import Backdrop from "@mui/material/Backdrop";
import Image from "../Image";
import logoImg from "../../assets/img/brand/branding-30.png";

export default function Spinner({ isLoading }) {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: 9000 }} open={isLoading}>
      <Image src={logoImg} className="rotate spinner-img2" />
    </Backdrop>
  );
}
