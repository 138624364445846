import axios from "axios";
import {
  TYPERESULT_ERROR_NO_CONTROLADO,
  TYPERESULT_EXITOSO,
} from "../constants/constants";
import { CognitoSesionActual } from "../services/autenticacion";

const ENV = "prod";

export function ObtenerAmbiente() {
  switch (ENV) {
    case "dev":
      return {
        UrlApi: "https://www.grupoinnovarhn.com/GrupoInnovarHnApiDev",
      };
    case "prod":
      return {
        UrlApi: "https://www.grupoinnovarhn.com/GrupoInnovarHnApi",
      };
    case "local":
      return {
        UrlApi: "http://localhost:4241/",
      };
    default:
      break;
  }
}

const Api = axios.create({
  baseURL: ObtenerAmbiente().UrlApi,
});

const ApiInterceptor = async (store) => {
  //Configuraciones por defecto de Axios -- Esto aplica a todas las peticiones.
  Api.defaults.headers.common["Content-Type"] = "application/json";
  const resp = await CognitoSesionActual();
  Api.defaults.headers.common["Authorization"] = resp?.accessToken?.jwtToken;
  Api.defaults.headers.common["JWT"] = resp?.idToken?.jwtToken;
  // Request interceptors
  Api.interceptors.request.use(
    async (config) => {
      return config;
    },
    (error) => {
      console.log("Request Error", error);
      return Promise.reject(error);
    }
  );

  // Response interceptors
  Api.interceptors.response.use(
    (response) => {
      //console.log("Response Success ", response);
      const data = response.data;
      if (data.typeResult !== TYPERESULT_EXITOSO) {
        throw new Error(data.Error);
      }
      return response;
    },
    (error) => {
      console.log("Response Error 1", error);
      let Response;
      switch (error.code) {
        case "ERR_NETWORK":
          Response = {
            message: "Ocurrió un error con la conexión",
            code: error.code,
            typeResult: TYPERESULT_ERROR_NO_CONTROLADO,
            result: null,
          };
          break;
        default:
          Response = {
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : "Ocurrió un error inesperado.",
            code: error.code,
            typeResult: error?.response?.data?.typeResult
              ? error?.response?.data?.typeResult
              : TYPERESULT_ERROR_NO_CONTROLADO,
            result: error?.response?.data?.result
              ? error?.response?.data?.result
              : null,
          };
          break;
      }
      if (error?.response?.status === 401) {
        localStorage.clear();
      }
      return Promise.reject(Response);
    }
  );
};
export { Api, ApiInterceptor };
