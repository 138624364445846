//Parámetros para el atributo typeResult que viene desde la API
export const TYPERESULT_EXITOSO = 0;
export const TYPERESULT_ERROR_CONTROLADO = 1;
export const TYPERESULT_ERROR_NO_CONTROLADO = 2;

//Roles de Usuarios
export const ROL_ADMIN = 1;
export const ROL_AGENTE_VENTAS = 2;
export const ROL_CLIENTE = 3;
export const ROL_COMISIONISTA = 4;
export const ROL_CONTADOR = 5;
export const ROL_REEMBOLSOS = 6;
export const ROL_COMPRAS = 7;

//Estados Lotes
export const LT_ACTIVO = 1;
export const LT_RESERVADO = 2;
export const LT_VENDIDO = 3;
export const LT_ELIMINADO = 4;
export const LT_NO_DISPONIBLE = 6;

//Estados Reservas
export const RESV_ACTIVA = 1;
export const RESV_INACTIVA = 2;
export const RESV_EXPIRADA = 3;
export const RESV_PENDIENTE = 4;
export const RESV_VENDIDA = 5;
export const RESV_VENDIDA_SIN_CONTRATO = 6;

//Topografías
export const TOP_PLANO = 1;
export const TOP_PENDIENTE_LEVE = 2;
export const TOP_PENDIENTE_MODERADA = 3;
export const TOP_PENDIENTE_MEDIA = 4;
export const TOP_PRONUNCIADA = 5;

//Valor M^2 => V^2
export const VALOR_VARAS = 1.4342572;

//Proyectos
export const RES_LA_PRADERA = 1;
export const RES_VILLA_REAL = 2;
export const RES_LOS_ROBLES = 3;
export const RES_VISTAS_BOSQUE = 4;
export const RES_SENDEROS_BOSQUE = 5;
export const RES_BUENA_VISTA = 6;

//Servicios
export const TIPO_CONSULTA_PLANO = 1;
export const TIPO_CONSULTA_TABLA = 2;

//Solicitudes Reembolso
export const REEMBOLSO_APROBADO = 1;
export const REEMBOLSO_RECHAZADO = 2;
export const REEMBOLSO_ENVIADO = 3;
export const REEMBOLSO_BORRRADOR = 4;
export const REEMBOLSO_PREAPROBADO = 5;

//Solicitudes Compras
export const COMPRA_BORRADOR = 1;
export const COMPRA_ENVIO_COTIZACION = 2;
export const COMPRA_COTIZACION_APROBADA = 4;
export const COMPRA_COTIZACION_RECHAZADA = 5;
export const COMPRA_ENVIO_COMPROBANTE = 6;
export const COMPRA_ESPERA_ENTREGA = 7;
export const COMPRA_ENTREGADA_BODEGA = 8;
export const COMPRA_CERRADA = 9;
export const COMPRA_REVISION = 11;
