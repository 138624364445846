import { useState, memo, useCallback, useEffect, useRef } from "react";
import Body from "../../components/Body";
import Spinner from "../../components/common/spinner";
import "./style.css";
import NuevoCustomModal from "../../components/Modal/NuevoCustomModal/index";
import {
  Add,
  AddCircle,
  AssignmentTurnedIn,
  Checklist,
  Delete,
  Description,
  Edit,
  FileUpload,
  Cancel,
  LocalShipping,
  MoneyOff,
  Pending,
  PriceCheck,
  RemoveCircle,
  RemoveRedEyeOutlined,
  RequestQuote,
  Send,
  Task,
  UploadFile,
  Warehouse,
  Visibility,
} from "@mui/icons-material";
import moment from "moment";
import {
  CerrarSolicitud,
  CrearSolicitudCompra,
  EditarCotizaciones,
  EditarSolicitudCompra,
  AprobarSolicitudCompra,
  RechazarSolicitudCompra,
  RechazarCotizacionCompra,
  EliminarComprobantesPago,
  EliminarCotizaciones,
  EliminarSolicitudCompra,
  EntregarArticulosSolicitud,
  EnviarComprobantes,
  EnviarCotizaciones,
  EnviarSolicitudCompra,
  GuardarComprobantesPago,
  GuardarCotizaciones,
  ObtenerArticulosSolicitud,
  ObtenerComprobantesPago,
  ObtenerCotizaciones,
  ObtenerSolicitudesCompras,
} from "../../services/compras";
import toast from "react-hot-toast";
import CustomMuiTextField from "../../components/Input";
import BotonCustom from "../../components/Button";
import {
  displayColumnDefOptions,
  estadoInicialGrid,
  estiloIcono,
  handleDecrypt,
  muiTableBodyProps,
  muiTableHeadCellProps,
  muiTableHeadProps,
  muiTablePaginationProps,
  muiTablePaperProps,
  muiTableProps,
  muiTopToolbarProps,
} from "../../util/util";
import {
  Alert,
  Grid,
  IconButton,
  MenuItem,
  Skeleton,
  Stack,
  Tooltip,
  Button,
} from "@mui/material";
import CustomMuiButton from "../../components/Button/customMuiButton";
import CustomMuiSelect from "../../components/Select";
import { useSelector } from "react-redux";
import { selectSession } from "../../features/sessionSlice";
import {
  CERRAR_SOLICITUD,
  CREAR_SOLICITUD_COMPRAS,
  EDITAR_SOLICITUD_COMPRAS,
  ELIMINAR_SOLICITUD_COMPRAS,
  ENTREGADA_BODEGA,
  ENVIAR_SOLICITUD_COMPRAS,
  SUBIR_COMPROBANTES_PAGO,
  SUBIR_COTIZACIONES_COMPRAS,
  VISUALIZAR_SOLICITUD,
  APROBAR_RECHAZAR_SOLICITUD_COMPRAS,
  RECHAZAR_COTIZACION_COMPRAS,
  VER_COMPRAS,
} from "../../constants/permisos";
import {
  CrearBancos,
  CrearBodega,
  CrearCuentaProveedores,
  CrearMetricas,
  CrearProveedores,
  ObtenerBancos,
  ObtenerBodegas,
  ObtenerListaCuentasProveedores,
  ObtenerMetricas,
  ObtenerPrioridades,
  ObtenerProveedores,
  ObtenerProyectos,
} from "../../services/catalogos";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";
import {
  COMPRA_BORRADOR,
  COMPRA_ENTREGADA_BODEGA,
  COMPRA_ENVIO_COMPROBANTE,
  COMPRA_ENVIO_COTIZACION,
  COMPRA_ESPERA_ENTREGA,
  COMPRA_REVISION,
} from "../../constants/constants";
import { storage } from "../../firebase";
import DeleteModal from "../../components/Modal/DeleteModal";
import ConfirmarModal from "../../components/Modal/ConfirmarModal";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable from "material-react-table";
import SearchableSelectBox from "../../components/AutoComplete";

const Compras = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isGridloading, setIsGridloading] = useState(true);
  const estadoInicialModal = {
    visible: false,
    titulo: "",
    funcion: () => {},
    botoneria: null,
    maxWidth: null,
  };
  const [modal, setModal] = useState(estadoInicialModal);
  const [filas, setFilas] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [prioridades, setPrioridades] = useState([]);
  const [isPrioridadesLoading, setIsPrioridadesLoading] = useState(true);
  const [isProyectosLoading, setIsProyectosLoading] = useState(true);
  const estadoInicialForm = {
    Codigo: 0,
    Razon: "",
    Observaciones: "",
    FKCodigoProyecto: 0,
    Proyecto: "",
    FKCodigoPrioridad: 0,
    Prioridad: "",
    NumeroCuenta: "",
    Rtn: "",
  };
  const [form, setForm] = useState(estadoInicialForm);
  const session = useSelector(selectSession);
  const permisos = JSON.parse(handleDecrypt(session.permisos));
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isEnviarModal, setIsEnviarModal] = useState(false);
  const [listaCotizaciones, setListaCotizaciones] = useState([]);
  const [listaComprobantes, setListaComprobantes] = useState([]);
  const [estadoBoton, setEstadoBoton] = useState(true);
  const [isCotizacionesLoading, setIsCotizacionesLoading] = useState(false);
  const [isComprobantesLoading, setIsComprobantesLoading] = useState(false);
  const estadoInicialArticulos = [
    { Id: 1, Codigo: 0, Nombre: "", Cantidad: 1 },
  ];
  const [arregloArticulos, setArregloArticulos] = useState(
    estadoInicialArticulos
  );
  const containerRef = useRef(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isArticulosLoading, setIsArticulosLoading] = useState(false);
  const [isProvLoading, setIsProvLoading] = useState(false);
  const [proveedores, setProveedores] = useState([]);
  const [isCuentasProvLoading, setIsCuentasProvLoading] = useState(false);
  const [cuentasProveedor, setCuentasProveedor] = useState([]);
  const [isMetricasLoading, setIsMetricasLoading] = useState(false);
  const [listaMetricas, setListaMetricas] = useState([]);
  const [isBodegasLoading, setIsBodegasLoading] = useState(false);
  const [listaBodegas, setListaBodegas] = useState([]);
  const [isBancosLoading, setIsBancosLoading] = useState(false);
  const [bancos, setBancos] = useState([]);

  const permisosActivarAcciones = [
    CREAR_SOLICITUD_COMPRAS,
    EDITAR_SOLICITUD_COMPRAS,
    ELIMINAR_SOLICITUD_COMPRAS,
    ENVIAR_SOLICITUD_COMPRAS,
    CERRAR_SOLICITUD,
    APROBAR_RECHAZAR_SOLICITUD_COMPRAS,
    RECHAZAR_COTIZACION_COMPRAS,
    SUBIR_COTIZACIONES_COMPRAS,
    ENTREGADA_BODEGA,
    VER_COMPRAS,
  ];

  function ObtenerIcono(valor) {
    if (valor === "Espera de Entrega") {
      return (
        <div className="flex gap-2 items-center">
          <LocalShipping
            style={{ fontSize: 20 }}
            color="inherit"
            className="mr-1 mb-1"
          />
          {valor}
        </div>
      );
    }
    if (valor === "Cerrada") {
      return (
        <div className="flex gap-2 items-center">
          <Task style={{ fontSize: 20 }} color="error" className="mr-1 mb-1" />
          {valor}
        </div>
      );
    }
    if (valor === "Entregada en Bodega") {
      return (
        <div className="flex gap-2 items-center">
          <Warehouse
            style={{ fontSize: 20 }}
            color="warning"
            className="mr-1 mb-1"
          />
          {valor}
        </div>
      );
    }
    if (valor === "Envío de Comprobante de Pago") {
      return (
        <div className="flex gap-2 items-center">
          <RequestQuote
            style={{ fontSize: 20 }}
            color="success"
            className="mr-1 mb-1"
          />
          {valor}
        </div>
      );
    }
    if (valor === "Cotización Rechazada") {
      return (
        <div className="flex gap-2 items-center">
          <MoneyOff
            style={{ fontSize: 20 }}
            color="warning"
            className="mr-1 mb-1"
          />
          {valor}
        </div>
      );
    }
    if (valor === "Cotización Aprobada") {
      return (
        <div className="flex gap-2 items-center">
          <PriceCheck
            style={{ fontSize: 20 }}
            color="warning"
            className="mr-1 mb-1"
          />
          {valor}
        </div>
      );
    }
    if (valor === "Envío de Cotización") {
      return (
        <div className="flex gap-2 items-center">
          <Description
            style={{ fontSize: 20 }}
            color="warning"
            className="mr-1 mb-1"
          />
          {valor}
        </div>
      );
    }
    if (valor === "Revisión") {
      return (
        <div className="flex gap-2">
          <Visibility
            style={{ fontSize: 20 }}
            color="info"
            className="mr-1 mb-1"
          />
          {valor}
        </div>
      );
    }
    if (valor === "Rechazada") {
      return (
        <div className="flex gap-2">
          <Cancel style={{ fontSize: 20 }} color="info" className="mr-1 mb-1" />
          {valor}
        </div>
      );
    }
    if (valor === "Borrador") {
      return (
        <div className="flex gap-2">
          <Pending
            style={{ fontSize: 20 }}
            color="info"
            className="mr-1 mb-1"
          />
          {valor}
        </div>
      );
    }
    if (valor === "Alta") {
      return <div className="text-red-500 font-bold">{valor}</div>;
    }
    if (valor === "Media") {
      return <div className="text-yellow-500 font-bold">{valor}</div>;
    }
    if (valor === "Baja") {
      return <div className="text-green-500 font-bold">{valor}</div>;
    }
  }

  const columnas = [
    {
      accessorKey: "Codigo",
      header: "Núm.",
      visible: true,
      filterFn: "equals",
      minSize: 100,
      Cell: ({ cell }) => <div className="text-center">{cell.getValue()}</div>,
    },
    {
      accessorKey: "Proyecto",
      header: "Proyecto",
      visible: true,
      Cell: ({ cell }) => (
        <div className="whitespace-pre-wrap">{cell.getValue()}</div>
      ),
    },
    {
      accessorKey: "Razon",
      header: "Razón",
      visible: true,
      muiTableHeadCellProps: {
        style: { whiteSpace: "normal", overfloWrap: "normal" },
      },
      Cell: ({ cell }) => (
        <div className="whitespace-pre-wrap">{cell.getValue()}</div>
      ),
    },
    {
      accessorKey: "Prioridad",
      header: "Prioridad",
      visible: true,
      minSize: 100,
      Cell: ({ cell }) => ObtenerIcono(cell.getValue()),
    },
    {
      accessorKey: "Observaciones",
      header: "Observaciones",
      visible: false,
      muiTableHeadCellProps: {
        style: { whiteSpace: "normal", overfloWrap: "normal" },
      },
      Cell: ({ cell }) => (
        <div className="whitespace-pre-wrap">{cell.getValue()}</div>
      ),
    },
    {
      accessorKey: "Estado",
      header: "Etapa",
      visible: true,
      Cell: ({ cell }) => ObtenerIcono(cell.getValue()),
    },
    {
      accessorKey: "FechaCreacion",
      header: "Creado El",
      visible: true,
      Cell: ({ cell }) => moment(cell.getValue()).format("DD / MM / YYYY"),
      filterFn: "equals",
      minSize: 120,
    },
    {
      accessorKey: "FechaModificacion",
      header: "Modificado El",
      visible: false,
      Cell: ({ cell }) => moment(cell.getValue()).format("DD / MM / YYYY"),
      filterFn: "equals",
      minSize: 120,
    },
  ];

  const ObtenerSolCompras = useCallback(async () => {
    try {
      setIsGridloading(true);
      const response = await ObtenerSolicitudesCompras();
      setFilas(response?.result ? JSON.parse(response.result) : []);
      setIsGridloading(false);
    } catch (err) {
      setIsGridloading(false);
      console.log("ObtenerSolCompras", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }, []);

  const ObtenerListaPrioridades = useCallback(async () => {
    try {
      setIsPrioridadesLoading(true);
      const response = await ObtenerPrioridades();
      setPrioridades(response?.result ? JSON.parse(response.result) : []);
      setIsPrioridadesLoading(false);
    } catch (err) {
      setIsPrioridadesLoading(false);
      console.log("ObtenerListaPrioridades", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }, []);

  const ObtenerListaProyectos = useCallback(async () => {
    try {
      setIsProyectosLoading(true);
      const response = await ObtenerProyectos();
      setProyectos(response?.result ? JSON.parse(response.result) : []);
      setIsProyectosLoading(false);
    } catch (err) {
      setIsProyectosLoading(false);
      console.log("ObtenerListaProyectos", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }, []);

  const ObtenerListaCotizaciones = useCallback(async (Codigo) => {
    try {
      setIsCotizacionesLoading(true);
      const response = await ObtenerCotizaciones(Codigo);
      setListaCotizaciones(() =>
        response?.result ? JSON.parse(response.result) : []
      );
      setIsCotizacionesLoading(false);
    } catch (err) {
      setIsCotizacionesLoading(false);
      console.log("ObtenerListaCotizaciones", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }, []);

  const ObtenerListaComprobantes = useCallback(async (Codigo) => {
    try {
      setIsComprobantesLoading(true);
      const response = await ObtenerComprobantesPago(Codigo);
      setListaComprobantes(() =>
        response?.result ? JSON.parse(response.result) : []
      );
      setIsComprobantesLoading(false);
    } catch (err) {
      setIsComprobantesLoading(false);
      console.log("ObtenerListaComprobantes", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }, []);

  const ObtenerArtiSolicitud = useCallback(async (CodigoSol) => {
    try {
      setIsArticulosLoading(true);
      const response = await ObtenerArticulosSolicitud(CodigoSol);
      setArregloArticulos(
        response?.result
          ? response.result === "[]"
            ? estadoInicialArticulos
            : JSON.parse(response.result)
          : estadoInicialArticulos
      );
      setIsArticulosLoading(false);
    } catch (err) {
      setIsArticulosLoading(false);
      console.log("ObtenerArtiSolicitud", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }, []);

  const ObtenerProveedoresCompra = useCallback(async () => {
    try {
      setIsProvLoading(true);
      const response = await ObtenerProveedores();
      let resp = response?.result ? JSON.parse(response.result) : [];

      resp = resp.map((item) => {
        return { label: item.Nombre, id: item.Codigo };
      });
      setProveedores(() => resp);
      setIsProvLoading(false);
    } catch (err) {
      setIsProvLoading(false);
      console.log("ObtenerProveedoresCompra", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }, []);

  const ObtenerCuentasProv = useCallback(async () => {
    try {
      setIsCuentasProvLoading(true);
      const response = await ObtenerListaCuentasProveedores();
      let resp = response?.result ? JSON.parse(response.result) : [];
      resp = resp.map((item) => {
        return {
          id: item.Codigo,
          label: item.NumeroCuenta,
          FKCodigoProveedor: item.FKCodigoProveedor,
          FKCodigoBanco: item.FKCodigoBanco,
        };
      });
      setCuentasProveedor(() => resp);
      setIsCuentasProvLoading(false);
    } catch (err) {
      setIsCuentasProvLoading(false);
      console.log("ObtenerCuentasProv", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }, []);

  const ObtenerListaMetricas = useCallback(async () => {
    try {
      setIsMetricasLoading(true);
      const response = await ObtenerMetricas();
      let resp = response?.result ? JSON.parse(response.result) : [];
      resp = resp.map((item) => {
        return { label: item.Nombre, id: item.Codigo };
      });
      setListaMetricas(() => resp);
      setIsMetricasLoading(false);
    } catch (err) {
      setIsMetricasLoading(false);
      console.log("ObtenerListaMetricas", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }, []);

  const ObtenerListaBodegas = useCallback(async () => {
    try {
      setIsBodegasLoading(true);
      const response = await ObtenerBodegas();
      let resp = response?.result ? JSON.parse(response.result) : [];
      resp = resp.map((item) => {
        return { label: item.Nombre, id: item.Codigo };
      });
      setListaBodegas(() => resp);
      setIsBodegasLoading(false);
    } catch (err) {
      setIsBodegasLoading(false);
      console.log("ObtenerListaBodegas", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }, []);

  const ObtenerListaBancos = useCallback(async () => {
    try {
      setIsBancosLoading(true);
      const response = await ObtenerBancos();
      let resp = response?.result ? JSON.parse(response.result) : [];
      resp = resp.map((item) => {
        return { label: item.Nombre, id: item.Codigo };
      });
      setBancos(() => resp);
      setIsBancosLoading(false);
    } catch (err) {
      setIsBancosLoading(false);
      console.log("ObtenerListaBancos", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }, []);

  useEffect(() => {
    ObtenerSolCompras();
    ObtenerListaPrioridades();
    ObtenerListaProyectos();
  }, [ObtenerSolCompras, ObtenerListaPrioridades, ObtenerListaProyectos]);

  async function ObtenerArchivosFirebase(rutaArchivo) {
    try {
      const listaArchivosRef = ref(
        storage,
        "Compras/" + rutaArchivo + "/" + form.Codigo
      );
      const response = await listAll(listaArchivosRef);
      const listaArchivos = [];
      for (const item of response?.items) {
        const pathParts = item.fullPath.split("/");
        const relevantPart = pathParts[3];
        const fileName = relevantPart;
        let urlArchivo = await getDownloadURL(item);
        listaArchivos.push({
          Url: urlArchivo,
          Ruta: item.fullPath,
          Nombre: fileName,
        });
      }
      return listaArchivos;
    } catch (err) {
      console.log("ObtenerArchivosFirebase", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }

  const CargarArchivosFirebase = async (e) => {
    try {
      setIsLoading(true);
      setEstadoBoton(false);
      const files = e.target.files;
      const allowedTypes = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "application/pdf",
      ];
      const validFiles = Array.from(files).filter((file) =>
        allowedTypes.includes(file.type)
      );
      const inValidFiles = Array.from(files).filter(
        (file) => !allowedTypes.includes(file.type)
      );
      if (validFiles.length === 0) {
        toast.dismiss();
        toast.error(
          "Error, solo es permitido subir archivos de tipo .png, .jpg, .jpeg y .pdf"
        );
        return;
      }
      if (inValidFiles.length > 0) {
        toast.dismiss();
        toast.error(
          "Error, solo es permitido subir archivos de tipo .png, .jpg, .jpeg y .pdf. Los archivos de otro tipo serán ignorados"
        );
      }
      for (const item of files) {
        const archivoRef = ref(
          storage,
          `Compras/Cotizaciones/${form.Codigo}/${item.name}`
        );
        await uploadBytes(archivoRef, item);
      }
      const respArchivosfirebase = await ObtenerArchivosFirebase(
        "Cotizaciones"
      );
      await GuardarCotizacionesSolicitud(respArchivosfirebase);
      await ObtenerListaCotizaciones(form.Codigo);
      setEstadoBoton(true);
      setIsLoading(false);
    } catch (err) {
      console.log("CargarArchivosFirebase", err);
      setIsLoading(false);
      toast.dismiss();
      toast.error(err?.message);
    }
  };

  const EliminarArchivoFirebase = async (path, Codigo, tipo) => {
    try {
      setIsLoading(true);
      const storage = getStorage();
      const fileRef = ref(storage, path);
      await deleteObject(fileRef);
      if (tipo === "Cotización") {
        const resp = await EliminarCotizaciones(Codigo);
        toast.success(resp?.message);
        await ObtenerListaCotizaciones(form.Codigo);
      }
      if (tipo === "Comprobante") {
        const resp = await EliminarComprobantesPago(Codigo);
        toast.success(resp?.message);
        await ObtenerListaComprobantes(form.Codigo);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(true);
      console.error("EliminarArchivoFirebase", error);
      toast.error(
        "Ocurrió un error inesperado al intentar eliminar el archivo. Porfavor intente de nuevo."
      );
    }
  };

  function CerrarModal() {
    setModal(() => estadoInicialModal);
    setForm(() => estadoInicialForm);
    setListaCotizaciones(() => []);
    setListaComprobantes(() => []);
    setArregloArticulos(() => estadoInicialArticulos);
  }

  async function CrearNuevaMetrica(Nombre) {
    try {
      setIsLoading(true);
      const resp = await CrearMetricas(JSON.stringify({ Nombre: Nombre }));
      toast.success(resp?.message);
      ObtenerListaMetricas();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("CrearNuevaMetrica", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }

  const CambiosArticulos = (id, event) => {
    setIsAdding(() => false);
    const { name, value } = event.target;
    if (name === "Cantidad" && isNaN(Number(value))) {
      return;
    }
    setArregloArticulos((prev) =>
      prev.map((item) =>
        item.Id === id ? { ...item, [name]: value.toUpperCase() } : item
      )
    );
  };

  const RemoverArticulo = (id) => {
    setIsAdding(() => false);
    if (arregloArticulos.length > 1) {
      setArregloArticulos((prev) => prev.filter((item) => item.Id !== id));
    }
  };

  const AgregarArticulo = () => {
    setArregloArticulos((prev) => {
      const newId =
        prev.length > 0 ? Math.max(...prev.map((item) => item.Id)) + 1 : 1;
      const newArticulo = { Id: newId, Codigo: 0, Nombre: "", Cantidad: 1 };
      return [...prev, newArticulo];
    });
    setIsAdding(() => true);
  };

  async function CrearNuevoProveedor(NombreProveedor, Rtn) {
    try {
      if (Rtn === null || Rtn === undefined) {
        toast.dismiss();
        toast.error("Error, debe ingresar el rtn del proveedor");
        return;
      }
      setIsLoading(true);
      const resp = await CrearProveedores(
        JSON.stringify({ Nombre: NombreProveedor, Rtn: Rtn })
      );
      toast.success(resp?.message);
      ObtenerProveedoresCompra();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("CrearNuevoProveedor", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }

  async function CrearNuevoBanco(NombreBanco) {
    try {
      setIsLoading(true);
      const resp = await CrearBancos(JSON.stringify({ Nombre: NombreBanco }));
      toast.success(resp?.message);
      ObtenerListaBancos();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("CrearNuevoBanco", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }

  async function CrearNuevaCuenta(NumeroCuenta, CodProveedor, CodBanco) {
    if (
      CodProveedor === null ||
      CodBanco === 0 ||
      CodBanco === undefined ||
      CodProveedor === 0 ||
      CodProveedor === undefined
    ) {
      toast.dismiss();
      toast.error("Error, debe seleccionar un proveedor y un banco");
      return;
    }
    try {
      setIsLoading(true);
      const resp = await CrearCuentaProveedores(
        JSON.stringify({
          NumeroCuenta: NumeroCuenta,
          FKCodigoProveedor: CodProveedor,
          FKCodigoBanco: CodBanco,
        })
      );
      toast.success(resp?.message);
      await ObtenerCuentasProv();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("CrearNuevaCuenta", err);
      toast.dismiss();
      toast.error(err?.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function CrearNuevaBodega(Nombre) {
    try {
      setIsLoading(true);
      const resp = await CrearBodega(JSON.stringify({ Nombre: Nombre }));
      toast.success(resp?.message);
      await ObtenerListaBodegas();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("CrearNuevaBodega", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }

  useEffect(() => {
    if (isAdding && containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [arregloArticulos, isAdding]);

  async function CrearSolicitud(info, datosArticulos) {
    try {
      setIsLoading(true);
      info.Aux = JSON.stringify(datosArticulos);
      const resp = await CrearSolicitudCompra(JSON.stringify(info));
      toast.success(resp?.message);
      ObtenerSolCompras();
      setIsLoading(false);
      CerrarModal();
    } catch (err) {
      setIsLoading(false);
      console.log("CrearSolicitud", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }

  async function ModalCrearSolicitud() {
    await ObtenerListaMetricas();
    setModal({
      titulo: "Nueva Solicitud de Compra",
      visible: true,
      funcion: CrearSolicitud,
      maxWidth: "w-4/5",
    });
  }

  async function ModalRevisarSolicitud(datos) {
    setIsLoading(true);
    setForm({
      Codigo: datos.Codigo,
      Proyecto: datos.Proyecto,
      Prioridad: datos.Prioridad,
      Razon: datos.Razon,
      Estado: datos.Estado,
    });
    await ObtenerArtiSolicitud(datos.Codigo);
    setModal({
      titulo: "Revision de Solicitud",
      visible: true,
      maxWidth: "w-4/5",
    });
    setIsLoading(false);
  }

  async function EditarSolicitud(info, infoArticulos, OcultarModal = true) {
    try {
      var registros =
        infoArticulos.find(
          (f) => f.Nombre === "" || f.Cantidad === "" || f.Cantidad === 0
        ) !== undefined;
      if (registros) {
        toast.dismiss();
        toast.error(
          "Error, todos los artículos ingresados deben tener un nombre y una cantidad mayor a 0"
        );
        return;
      } else {
        setIsLoading(true);
        info.Aux = JSON.stringify(infoArticulos);
        const resp = await EditarSolicitudCompra(JSON.stringify(info));
        toast.success(resp?.message);
        ObtenerSolCompras();
        setIsLoading(false);
        OcultarModal && CerrarModal();
      }
    } catch (err) {
      setIsLoading(false);
      console.log("EditarSolicitud", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }

  async function ModalEditarSolicitud(datos) {
    setIsLoading(true);
    setForm({
      Codigo: datos.Codigo,
      FKCodigoPrioridad: datos.FKCodigoPrioridad,
      FKCodigoProyecto: datos.FKCodigoProyecto,
      Razon: datos.Razon,
    });
    await ObtenerArtiSolicitud(datos.Codigo);
    await ObtenerListaMetricas();
    setModal({
      titulo: "Editar Solicitud de Compra",
      visible: true,
      funcion: EditarSolicitud,
      maxWidth: "w-4/5",
    });
    setIsLoading(false);
  }

  async function EliminarSolicitud() {
    try {
      setIsLoading(true);
      const resp = await EliminarSolicitudCompra(form.Codigo);
      toast.success(resp?.message);
      ObtenerSolCompras();
      setIsLoading(false);
      setIsDeleteModal(() => false);
    } catch (err) {
      setIsLoading(false);
      console.log("EliminarSolicitud", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }

  async function ModalEliminar(valores) {
    if (valores.Estado !== "Borrador") {
      toast.dismiss();
      toast.error(
        "Error, la solicitud ya ha sido enviada así que no se puede eliminar"
      );
      return;
    }
    setForm({ Codigo: valores.Codigo });
    setIsDeleteModal(() => true);
  }

  async function EnviarSolicitud() {
    try {
      setIsLoading(true);
      let datos = {
        Codigo: form.Codigo,
      };
      const resp = await EnviarSolicitudCompra(JSON.stringify(datos));
      toast.success(resp?.message);
      ObtenerSolCompras();
      setIsLoading(false);
      setIsEnviarModal(() => false);
    } catch (err) {
      setIsLoading(false);
      console.log("EnviarSolicitud", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }

  async function AprobarSolicitud() {
    try {
      setIsLoading(true);
      let datos = {
        Codigo: form.Codigo,
      };
      const resp = await AprobarSolicitudCompra(JSON.stringify(datos));
      toast.success(resp?.message);
      ObtenerSolCompras();
      setIsLoading(false);
      CerrarModal();
    } catch (err) {
      setIsLoading(false);
      toast.dismiss();
      toast.error(err?.message);
    }
  }

  async function RechazarSolicitud() {
    try {
      setIsLoading(true);
      let datos = {
        Codigo: form.Codigo,
      };
      const resp = await RechazarSolicitudCompra(JSON.stringify(datos));
      toast.success(resp?.message);
      ObtenerSolCompras();
      setIsLoading(false);
      CerrarModal();
    } catch (err) {
      setIsLoading(false);
      toast.dismiss();
      toast.error(err?.message);
    }
  }

  async function RechazarCotizacion() {
    try {
      setIsLoading(true);
      let datos = {
        Codigo: form.Codigo,
        Observaciones: form.Observaciones,
      };
      const resp = await RechazarCotizacionCompra(JSON.stringify(datos));
      toast.success(resp?.message);
      ObtenerSolCompras();
      setIsLoading(false);
      CerrarModal();
    } catch (err) {
      setIsLoading(false);
      toast.dismiss();
      toast.error(err?.message);
    }
  }

  async function ModalEnviar(valores) {
    if (valores.Estado === "Envío de Cotización") {
      toast.dismiss();
      toast.error("Error, la solicitud ya ha sido enviada.");
      return;
    }
    setForm({ ...form, Codigo: valores.Codigo });
    setIsEnviarModal(() => true);
  }

  async function EnviarCotizacionesSolicitud(datos, infoArticulos) {
    try {
      setIsLoading(true);
      const resp = await EnviarCotizaciones(
        JSON.stringify({
          Codigo: datos.Codigo,
          Aux: JSON.stringify(infoArticulos),
        })
      );
      toast.success(resp?.message);
      ObtenerSolCompras();
      setIsLoading(false);
      CerrarModal();
    } catch (err) {
      setIsLoading(false);
      console.log("EnviarCotizacionesSolicitud", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }

  async function ModalSubirCotizaciones(datos) {
    setIsLoading(true);
    await ObtenerListaCotizaciones(datos.Codigo);
    await ObtenerCuentasProv();
    await ObtenerProveedoresCompra();
    await ObtenerListaMetricas();
    await ObtenerListaBancos();
    setForm({
      Codigo: datos.Codigo,
      Prioridad: datos.Prioridad,
      Proyecto: datos.Proyecto,
      Razon: datos.Razon,
      Observaciones: datos.Observaciones,
    });
    await ObtenerArtiSolicitud(datos.Codigo);
    setModal({
      titulo: "Subir Cotización",
      visible: true,
      maxWidth: "w-4/5",
    });
    setIsLoading(false);
  }

  async function GuardarCotizacionesSolicitud(listaArchivos) {
    try {
      let info = {
        FKCodigoSolicitudCompra: form.Codigo,
        Aux: JSON.stringify(listaArchivos),
      };
      const response = await GuardarCotizaciones(JSON.stringify(info));
      toast.dismiss();
      toast.success(response?.message);
    } catch (err) {
      console.log("GuardarCotizacionesSolicitud", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }

  async function EditarCotizacionesSolicitud(CodigoSol, listaCotiz) {
    try {
      setIsLoading(true);
      let info = {
        FKCodigoSolicitudCompra: CodigoSol,
        Aux: JSON.stringify(listaCotiz),
      };
      const response = await EditarCotizaciones(JSON.stringify(info));
      toast.success(response?.message);
      await ObtenerListaCotizaciones(CodigoSol);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("EditarCotizacionesSolicitud", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }

  async function ModalSubirComprobantesPago(datos) {
    setIsLoading(true);
    await ObtenerListaComprobantes(datos.Codigo);
    await ObtenerListaCotizaciones(datos.Codigo);
    setForm({
      Codigo: datos.Codigo,
      Prioridad: datos.Prioridad,
      Proyecto: datos.Proyecto,
      Razon: datos.Razon,
    });
    setModal({
      titulo: "Subir Comprobantes de Pago",
      visible: true,
      maxWidth: "w-3/5",
    });
    setIsLoading(false);
  }

  async function GuardarComprobantesSolicitud(listaArchivos) {
    try {
      let info = {
        FKCodigoSolicitudCompra: form.Codigo,
        Aux: JSON.stringify(listaArchivos),
      };
      const response = await GuardarComprobantesPago(JSON.stringify(info));
      toast.dismiss();
      toast.success(response?.message);
    } catch (err) {
      console.log("GuardarComprobantesSolicitud", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }

  const CargarComprobantesArchivosFirebase = async (e) => {
    try {
      setIsLoading(true);
      setEstadoBoton(false);
      const files = e.target.files;
      const allowedTypes = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "application/pdf",
      ];
      const validFiles = Array.from(files).filter((file) =>
        allowedTypes.includes(file.type)
      );
      const inValidFiles = Array.from(files).filter(
        (file) => !allowedTypes.includes(file.type)
      );
      if (validFiles.length === 0) {
        toast.dismiss();
        toast.error(
          "Error, solo es permitido subir archivos de tipo .png, .jpg, .jpeg y .pdf"
        );
        return;
      }
      if (inValidFiles.length > 0) {
        toast.dismiss();
        toast.error(
          "Error, solo es permitido subir archivos de tipo .png, .jpg, .jpeg y .pdf. Los archivos de otro tipo serán ignorados"
        );
      }
      for (const item of files) {
        const archivoRef = ref(
          storage,
          `Compras/Comprobantes/${form.Codigo}/${item.name}`
        );
        await uploadBytes(archivoRef, item);
      }
      const respArchivosfirebase = await ObtenerArchivosFirebase(
        "Comprobantes"
      );
      await GuardarComprobantesSolicitud(respArchivosfirebase);
      await ObtenerListaComprobantes(form.Codigo);
      setEstadoBoton(true);
      setIsLoading(false);
    } catch (err) {
      console.log("CargarComprobantesArchivosFirebase", err);
      setIsLoading(false);
      toast.dismiss();
      toast.error(err?.message);
    }
  };

  async function EnviarComprobantesSolicitud(datos) {
    try {
      setIsLoading(true);
      const resp = await EnviarComprobantes(
        JSON.stringify({ Codigo: datos.Codigo })
      );
      toast.success(resp?.message);
      ObtenerSolCompras();
      setIsLoading(false);
      CerrarModal();
    } catch (err) {
      setIsLoading(false);
      console.log("EnviarComprobantesSolicitud", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }

  async function ModalEntregadaBodega(datos) {
    setIsLoading(true);
    await ObtenerArtiSolicitud(datos.Codigo);
    await ObtenerListaBodegas();
    setForm({
      Codigo: datos.Codigo,
      FKCodigoPrioridad: datos.FKCodigoPrioridad,
      FKCodigoProyecto: datos.FKCodigoProyecto,
      Razon: datos.Razon,
    });
    setModal({
      titulo: "¿Entregado en Bodega?",
      visible: true,
      maxWidth: "w-4/5",
    });
    setIsLoading(false);
  }

  async function EntregarArticulosSol(Codigo, datos) {
    try {
      setIsLoading(true);
      const resp = await EntregarArticulosSolicitud(
        JSON.stringify({ Codigo: Codigo, Aux: JSON.stringify(datos) })
      );
      toast.success(resp?.message);
      setIsLoading(false);
      CerrarModal();
      ObtenerSolCompras();
    } catch (err) {
      setIsLoading(false);
      console.log("EntregarArticulosSol", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }

  async function CerrarSol(Codigo) {
    try {
      setIsLoading(true);
      const resp = await CerrarSolicitud(JSON.stringify({ Codigo: Codigo }));
      toast.success(resp?.message);
      setIsLoading(false);
      CerrarModal();
      ObtenerSolCompras();
    } catch (err) {
      setIsLoading(false);
      console.log("CerrarSol", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }

  async function ModalCierreSolicitud(datos) {
    setIsLoading(true);
    setForm({
      Codigo: datos.Codigo,
    });
    setModal({
      titulo: "¿Cerrar Solicitud?",
      visible: true,
      maxWidth: "w-auto",
    });
    setIsLoading(false);
  }

  async function ModalVerSolicitud(datos) {
    setIsLoading(true);
    setForm({
      Codigo: datos.Codigo,
      Proyecto: datos.Proyecto,
      Prioridad: datos.Prioridad,
      Razon: datos.Razon,
      Estado: datos.Estado,
    });
    await ObtenerListaComprobantes(datos.Codigo);
    await ObtenerListaCotizaciones(datos.Codigo);
    await ObtenerArtiSolicitud(datos.Codigo);
    setModal({
      titulo: "Solicitud de Compra",
      visible: true,
      maxWidth: "w-3/5",
    });
    setIsLoading(false);
  }

  return (
    <main className="body--container aic anim">
      <Spinner isLoading={isLoading} />
      <div className="Detalle-tabla aic anim fromleft">
        <div className="Detalle-tabla-container aic anim">
          <div className="Detalle-tabla-container-title aic anim">
            <div className="Detalle-tabla-container-title-left aic anim">
              <div className="Detalle-tabla-container-title-left-block aic anim">
                <h2 className="Detalle-tabla-container-title-left-block-title">
                  Control de Compras
                </h2>
                <span className="Detalle-tabla-container-title-left-block-body aic">
                  Solicite sus compras
                </span>
              </div>
            </div>
          </div>
          <NuevoCustomModal
            onClose={CerrarModal}
            visible={modal.visible}
            titulo={modal.titulo}
            maxWidth={modal.maxWidth}
            botoneria={
              <>
                {modal.titulo === "Revision de Solicitud" && (
                  <div className="Modal-btns aic">
                    <BotonCustom ghost type="button" onClick={CerrarModal}>
                      Cancelar
                    </BotonCustom>
                    <BotonCustom
                      onClick={() =>
                        setModal({
                          ...form,
                          titulo: "Aprobar Solicitud",
                          visible: true,
                          maxWidth: "w-1/4",
                        })
                      }
                      type="button"
                    >
                      Aprobar
                    </BotonCustom>
                    <BotonCustom
                      onClick={() =>
                        setModal({
                          ...form,
                          titulo: "Rechazar Solicitud",
                          visible: true,
                          maxWidth: "w-1/4",
                        })
                      }
                      type="button"
                    >
                      Rechazar
                    </BotonCustom>
                  </div>
                )}
                {modal.titulo === "Aprobar Solicitud" && (
                  <div className="Modal-btns aic mt-4">
                    <BotonCustom ghost type="button" onClick={CerrarModal}>
                      Cancelar
                    </BotonCustom>
                    <BotonCustom
                      onClick={() =>
                        setModal({
                          ...form,
                          titulo: "Revision de Solicitud",
                          visible: true,
                          maxWidth: "w-4/5",
                        })
                      }
                      type="button"
                    >
                      Regresar
                    </BotonCustom>
                    <BotonCustom
                      onClick={async () => AprobarSolicitud(form.Codigo)}
                      type="button"
                    >
                      Aprobar
                    </BotonCustom>
                  </div>
                )}
                {modal.titulo === "Rechazar Solicitud" && (
                  <div className="Modal-btns aic mt-4">
                    <BotonCustom ghost type="button" onClick={CerrarModal}>
                      Cancelar
                    </BotonCustom>
                    <BotonCustom
                      onClick={() =>
                        setModal({
                          ...form,
                          titulo: "Revision de Solicitud",
                          visible: true,
                          maxWidth: "w-4/5",
                        })
                      }
                      type="button"
                    >
                      Regresar
                    </BotonCustom>
                    <BotonCustom
                      onClick={async () => RechazarSolicitud(form.Codigo)}
                      type="button"
                    >
                      Rechazar
                    </BotonCustom>
                  </div>
                )}
                {(modal.titulo === "Nueva Solicitud de Compra" ||
                  modal.titulo === "Editar Solicitud de Compra") && (
                  <div className="Modal-btns aic">
                    <BotonCustom ghost type="button" onClick={CerrarModal}>
                      Cancelar
                    </BotonCustom>
                    <BotonCustom
                      onClick={() => modal.funcion(form, arregloArticulos)}
                      type="button"
                    >
                      Guardar
                    </BotonCustom>
                  </div>
                )}
                {modal.titulo === "Subir Cotización" && (
                  <div className="Modal-btns aic">
                    <BotonCustom ghost type="button" onClick={CerrarModal}>
                      Cancelar
                    </BotonCustom>
                    <BotonCustom
                      onClick={async () => {
                        await EditarSolicitud(form, arregloArticulos, false);
                        listaCotizaciones.length > 0 &&
                          (await EditarCotizacionesSolicitud(
                            form.Codigo,
                            listaCotizaciones
                          ));
                      }}
                      type="button"
                    >
                      Guardar
                    </BotonCustom>
                    <BotonCustom
                      onClick={() =>
                        setModal({
                          ...form,
                          titulo: "Enviar Cotizaciones",
                          visible: true,
                          maxWidth: "w-1/5",
                        })
                      }
                      type="button"
                    >
                      Enviar
                    </BotonCustom>
                  </div>
                )}
                {modal.titulo === "Enviar Cotizaciones" && (
                  <div className="Modal-btns aic mt-4">
                    <BotonCustom ghost type="button" onClick={CerrarModal}>
                      Cancelar
                    </BotonCustom>
                    <BotonCustom
                      onClick={() =>
                        setModal({
                          ...form,
                          titulo: "Subir Cotización",
                          visible: true,
                          maxWidth: "w-4/5",
                        })
                      }
                      type="button"
                    >
                      Regresar
                    </BotonCustom>
                    <BotonCustom
                      onClick={async () =>
                        EnviarCotizacionesSolicitud(form, arregloArticulos)
                      }
                      type="button"
                    >
                      Enviar
                    </BotonCustom>
                  </div>
                )}
                {modal.titulo === "Subir Comprobantes de Pago" && (
                  <div className="Modal-btns aic">
                    <BotonCustom ghost type="button" onClick={CerrarModal}>
                      Cancelar
                    </BotonCustom>
                    <BotonCustom
                      onClick={() =>
                        setModal({
                          ...form,
                          titulo: "Rechazar Cotización",
                          visible: true,
                          maxWidth: "w-1.5/5",
                        })
                      }
                      type="button"
                    >
                      Rechazar
                    </BotonCustom>
                    <BotonCustom
                      onClick={() =>
                        setModal({
                          ...form,
                          titulo: "Enviar Comprobantes de Pago",
                          visible: true,
                          maxWidth: "w-2/5",
                        })
                      }
                      type="button"
                    >
                      Enviar
                    </BotonCustom>
                  </div>
                )}
                {modal.titulo === "Rechazar Cotización" && (
                  <div className="Modal-btns aic mt-4">
                    <BotonCustom ghost type="button" onClick={CerrarModal}>
                      Cancelar
                    </BotonCustom>
                    <BotonCustom
                      onClick={() =>
                        setModal({
                          ...form,
                          titulo: "Subir Comprobantes de Pago",
                          visible: true,
                          maxWidth: "w-3/5",
                        })
                      }
                      type="button"
                    >
                      Regresar
                    </BotonCustom>
                    <BotonCustom
                      onClick={async () =>
                        RechazarCotizacion(form.Codigo, form.Observaciones)
                      }
                      type="button"
                    >
                      Rechazar
                    </BotonCustom>
                  </div>
                )}
                {modal.titulo === "Enviar Comprobantes de Pago" && (
                  <div className="Modal-btns aic">
                    <BotonCustom ghost type="button" onClick={CerrarModal}>
                      Cancelar
                    </BotonCustom>
                    <BotonCustom
                      onClick={() =>
                        setModal({
                          ...form,
                          titulo: "Subir Comprobantes de Pago",
                          visible: true,
                          maxWidth: "w-3/5",
                        })
                      }
                      type="button"
                    >
                      Regresar
                    </BotonCustom>
                    <BotonCustom
                      onClick={() => EnviarComprobantesSolicitud(form)}
                      type="button"
                    >
                      Enviar
                    </BotonCustom>
                  </div>
                )}
                {modal.titulo === "¿Entregado en Bodega?" && (
                  <div className="Modal-btns aic">
                    <BotonCustom ghost type="button" onClick={CerrarModal}>
                      Cancelar
                    </BotonCustom>
                    <BotonCustom
                      onClick={async () => {
                        await EntregarArticulosSol(
                          form.Codigo,
                          arregloArticulos
                        );
                      }}
                      type="button"
                    >
                      Guardar
                    </BotonCustom>
                  </div>
                )}
                {modal.titulo === "¿Cerrar Solicitud?" && (
                  <div className="Modal-btns aic">
                    <BotonCustom ghost type="button" onClick={CerrarModal}>
                      Cancelar
                    </BotonCustom>
                    <BotonCustom
                      onClick={() => CerrarSol(form.Codigo)}
                      type="button"
                    >
                      Cerrar Solicitud
                    </BotonCustom>
                  </div>
                )}
                {modal.titulo === "Solicitud de Compra" && (
                  <div className="Modal-btns aic">
                    <BotonCustom ghost type="button" onClick={CerrarModal}>
                      Cerrar
                    </BotonCustom>
                  </div>
                )}
              </>
            }
          >
            {(modal.titulo === "Nueva Solicitud de Compra" ||
              modal.titulo === "Editar Solicitud de Compra") && (
              <div className="flex flex-col gap-2 h-full mt-4 p-2">
                <CustomMuiTextField
                  fullWidth={true}
                  label="Razón"
                  name="Razon"
                  placeholder="Razón"
                  value={form.Razon}
                  onChange={(e) => {
                    setForm((prev) => {
                      return { ...prev, Razon: e.target.value };
                    });
                  }}
                  required
                  multiline
                  InputPropsOptions={{ maxLength: 250 }}
                />
                <div className="flex gap-1 w-full">
                  <CustomMuiSelect
                    fullWidth={true}
                    label="Proyecto"
                    isLoading={isProyectosLoading}
                    name="SlctProyecto"
                    value={form.FKCodigoProyecto}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        FKCodigoProyecto: e.target.value
                          ? parseInt(e.target.value)
                          : "0",
                      });
                    }}
                  >
                    <MenuItem value={"0"}>Seleccione un Proyecto</MenuItem>
                    {proyectos.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.Codigo}>
                          {item.Nombre}
                        </MenuItem>
                      );
                    })}
                  </CustomMuiSelect>
                  <CustomMuiSelect
                    fullWidth={true}
                    label="Prioridad"
                    isLoading={isPrioridadesLoading}
                    name="SlctPrioridad"
                    value={form.FKCodigoPrioridad}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        FKCodigoPrioridad: e.target.value
                          ? parseInt(e.target.value)
                          : "0",
                      });
                    }}
                  >
                    <MenuItem value={"0"}>Seleccione una Prioridad</MenuItem>
                    {prioridades.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.Codigo}>
                          {item.Nombre}
                        </MenuItem>
                      );
                    })}
                  </CustomMuiSelect>
                </div>
                <Alert severity="info">
                  Ingrese los artículos que se desean comprar y sus respectivas
                  cantidades.
                </Alert>
                <div className="mt-2 flex flex-col gap-2 w-full">
                  <h4 className="font-bold ml-2">Artículos</h4>
                  <div
                    className="flex flex-col gap-2 w-full overflow-y-scroll max-h-40 mt-2"
                    ref={containerRef}
                  >
                    {arregloArticulos.map((item, index) => {
                      return (
                        <div
                          className="flex gap-1 items-center p-1 mt-1"
                          key={index}
                        >
                          <CustomMuiTextField
                            label="Nombre"
                            name="Nombre"
                            placeholder="Nombre "
                            value={item.Nombre.toUpperCase()}
                            onChange={(e) => CambiosArticulos(item.Id, e)}
                            required
                            multiline
                            fullWidth={true}
                            InputPropsOptions={{ maxLength: 250 }}
                          />
                          <SearchableSelectBox
                            createLabel="Crear Métrica"
                            onCreateNew={CrearNuevaMetrica}
                            loading={isMetricasLoading}
                            options={listaMetricas}
                            fullWidth={true}
                            name="AcMetricas"
                            value={{
                              id: item?.FKCodigoMetrica || "",
                              label: item?.Metrica || "",
                            }}
                            onChange={(e, value) => {
                              setArregloArticulos((prev) => {
                                const newArr = [...prev];
                                newArr[index] = {
                                  ...newArr[index],
                                  FKCodigoMetrica: value?.id,
                                  Metrica: value?.label,
                                };
                                return newArr;
                              });
                            }}
                            label="Métricas"
                          />
                          <CustomMuiTextField
                            label="Cantidad"
                            name="Cantidad"
                            placeholder="Cantidad"
                            value={item.Cantidad}
                            onChange={(e) => CambiosArticulos(item.Id, e)}
                            required
                            sx={{ width: "30%" }}
                            InputPropsOptions={{ maxLength: 22 }}
                          />
                          <div className="flex flex-col gap-1">
                            <Tooltip title="Eliminar" placement="top">
                              <RemoveCircle
                                style={{ fontSize: 20 }}
                                color="error"
                                className="mb-1 hover:cursor-pointer hover:scale-110"
                                onClick={(e) => RemoverArticulo(item.Id)}
                              />
                            </Tooltip>
                            {arregloArticulos.length - 1 === index && (
                              <Tooltip title="Agregar" placement="top">
                                <AddCircle
                                  style={{ fontSize: 20 }}
                                  color="success"
                                  className="mb-1 hover:cursor-pointer hover:scale-110"
                                  onClick={AgregarArticulo}
                                />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    {isArticulosLoading && (
                      <div className="mx-1">
                        <Grid container spacing={1}>
                          <Grid item xs={9}>
                            <Skeleton
                              variant="rounded"
                              sx={{ fontSize: "2rem" }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Skeleton
                              variant="rounded"
                              sx={{ fontSize: "2rem" }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {modal.titulo === "Subir Cotización" && (
              <div className="flex flex-col gap-2 w-full h-full mt-4 p-2">
                <CustomMuiTextField
                  fullWidth={true}
                  label="Razón"
                  name="Razon"
                  placeholder="Razón"
                  value={form.Razon}
                  onChange={(e) => {
                    setForm((prev) => {
                      return { ...prev, Razon: e.target.value };
                    });
                  }}
                  readOnly={true}
                  multiline
                  InputPropsOptions={{ maxLength: 250 }}
                />
                <div className="flex gap-1">
                  <CustomMuiTextField
                    fullWidth={true}
                    label="Proyecto"
                    name="Proyecto"
                    placeholder="Proyecto"
                    value={form.Proyecto}
                    readOnly={true}
                    multiline
                  />
                  <CustomMuiTextField
                    fullWidth={true}
                    label="Prioridad"
                    name="Prioridad"
                    placeholder="Prioridad"
                    value={form.Prioridad}
                    readOnly={true}
                  />
                </div>
                {form.Observaciones && form.Observaciones.trim() !== "" && (
                  <CustomMuiTextField
                    fullWidth={true}
                    label="Observaciones"
                    name="Observaciones"
                    placeholder="Observaciones"
                    value={form.Observaciones}
                    onChange={(e) => {
                      setForm((prev) => {
                        return { ...prev, Observaciones: e.target.value };
                      });
                    }}
                    readOnly={true}
                    multiline
                    InputPropsOptions={{ maxLength: 250 }}
                  />
                )}
                <Alert severity="info">
                  Ingrese los artículos que se desean comprar y sus respectivas
                  cantidades.
                </Alert>
                <div className="mt-2 flex flex-col gap-2 w-full">
                  <h4 className="font-bold ml-2">Artículos</h4>
                  <div
                    className="flex flex-col gap-2 w-full overflow-y-scroll max-h-36 mt-2"
                    ref={containerRef}
                  >
                    {arregloArticulos.map((item, index) => {
                      return (
                        <div
                          className="flex gap-1 items-center p-1"
                          key={index}
                        >
                          <CustomMuiTextField
                            label="Nombre"
                            name="Nombre"
                            placeholder="Nombre "
                            value={item.Nombre.toUpperCase()}
                            onChange={(e) => CambiosArticulos(item.Id, e)}
                            required
                            multiline
                            fullWidth={true}
                            InputPropsOptions={{ maxLength: 250 }}
                          />
                          <SearchableSelectBox
                            createLabel="Crear Métrica"
                            onCreateNew={CrearNuevaMetrica}
                            loading={isMetricasLoading}
                            options={listaMetricas}
                            fullWidth={true}
                            name="AcMetricas"
                            value={{
                              id: item?.FKCodigoMetrica || "",
                              label: item?.Metrica || "",
                            }}
                            onChange={(e, value) => {
                              setArregloArticulos((prev) => {
                                const newArr = [...prev];
                                newArr[index] = {
                                  ...newArr[index],
                                  FKCodigoMetrica: value?.id,
                                  Metrica: value?.label,
                                };
                                return newArr;
                              });
                            }}
                            label="Métricas"
                          />
                          <CustomMuiTextField
                            label="Cantidad"
                            name="Cantidad"
                            placeholder="Cantidad"
                            value={item.Cantidad}
                            onChange={(e) => CambiosArticulos(item.Id, e)}
                            required
                            sx={{ width: "30%" }}
                            InputPropsOptions={{ maxLength: 22 }}
                          />
                          <div className="flex flex-col gap-1">
                            <Tooltip title="Eliminar" placement="top">
                              <RemoveCircle
                                style={{ fontSize: 20 }}
                                color="error"
                                className="mb-1 hover:cursor-pointer hover:scale-110"
                                onClick={(e) => RemoverArticulo(item.Id)}
                              />
                            </Tooltip>
                            {arregloArticulos.length - 1 === index && (
                              <Tooltip title="Agregar" placement="top">
                                <AddCircle
                                  style={{ fontSize: 20 }}
                                  color="success"
                                  className="mb-1 hover:cursor-pointer hover:scale-110"
                                  onClick={AgregarArticulo}
                                />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    {isArticulosLoading && (
                      <div className="mx-1">
                        <Grid container spacing={1}>
                          <Grid item xs={9}>
                            <Skeleton
                              variant="rounded"
                              sx={{ fontSize: "2rem" }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Skeleton
                              variant="rounded"
                              sx={{ fontSize: "2rem" }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </div>
                </div>
                {estadoBoton && (
                  <div>
                    <input
                      accept=".png,.jpg,.jpeg,.pdf"
                      style={{ display: "none" }}
                      id="IptSubirCotizacion"
                      multiple={true}
                      type="file"
                      onChange={CargarArchivosFirebase}
                    />
                    <label htmlFor="IptSubirCotizacion">
                      <CustomMuiButton
                        name="BtnSubirCotizacion"
                        id="BtnSubirCotizacion"
                        variant="contained"
                        component="span"
                        style={{ fontSize: 11 }}
                        buttomName="Subir Cotización"
                        icon={<FileUpload style={estiloIcono} />}
                      />
                    </label>
                  </div>
                )}
                <div className="flex-col flex-nowrap gap-2 w-full">
                  <h4 className="font-bold mb-2">Cotizaciones</h4>
                  {listaCotizaciones.length > 0 && (
                    <Grid container spacing={2}>
                      {listaCotizaciones.map((item, index) => {
                        return (
                          <Grid key={index} item xs={12}>
                            <div className="flex flex-col gap-2">
                              <div>
                                <Tooltip
                                  title="Ver Cotización"
                                  placement="top"
                                  className="hover:cursor-pointer"
                                  onClick={() =>
                                    window.open(item.Url, "_blank")
                                  }
                                >
                                  {item.Nombre}
                                </Tooltip>
                              </div>
                              <div className="flex flex-nowrap gap-2 items-center">
                                <SearchableSelectBox
                                  createLabel="Crear Proveedor"
                                  onCreateNew={(e) =>
                                    CrearNuevoProveedor(e, item.Rtn)
                                  }
                                  loading={isProvLoading}
                                  options={proveedores}
                                  fullWidth={true}
                                  name="AcProveedores"
                                  value={{
                                    id: item?.FKCodigoProveedor || "",
                                    label: item?.Proveedor || "",
                                  }}
                                  onChange={async (e, value) => {
                                    const newCotizaciones = listaCotizaciones;
                                    newCotizaciones[index] = {
                                      ...newCotizaciones[index],
                                      FKCodigoProveedor: value?.id,
                                      Proveedor: value?.label,
                                      FKCodigoCuentaProveedor: "",
                                      NumeroCuenta: "",
                                      Rtn: "",
                                    };
                                    await EditarCotizacionesSolicitud(
                                      form.Codigo,
                                      newCotizaciones
                                    );
                                  }}
                                  label="Proveedor"
                                />
                                <CustomMuiTextField
                                  fullWidth={true}
                                  label="RTN"
                                  name="RTN"
                                  placeholder="RTN"
                                  value={item?.Rtn || ""}
                                  onChange={(e) => {
                                    if (!isNaN(Number(e.target.value))) {
                                      setListaCotizaciones((prev) => {
                                        const newCotizaciones = [...prev];
                                        newCotizaciones[index] = {
                                          ...newCotizaciones[index],
                                          Rtn: e.target.value.trim(),
                                        };
                                        return newCotizaciones;
                                      });
                                    }
                                  }}
                                  InputPropsOptions={{ maxLength: 14 }}
                                />
                                <SearchableSelectBox
                                  createLabel="Crear Banco"
                                  onCreateNew={(e) => CrearNuevoBanco(e)}
                                  loading={isBancosLoading}
                                  options={bancos}
                                  fullWidth={true}
                                  name="AcBancosCuentasProveedores"
                                  value={{
                                    id: item?.FKCodigoBanco || "",
                                    label: item?.Banco || "",
                                  }}
                                  onChange={async (e, value) => {
                                    const newCotizaciones = listaCotizaciones;
                                    newCotizaciones[index] = {
                                      ...newCotizaciones[index],
                                      FKCodigoBanco: value?.id,
                                      Banco: value?.label,
                                      FKCodigoCuentaProveedor: "",
                                      NumeroCuenta: "",
                                    };

                                    setListaCotizaciones([...newCotizaciones]);
                                  }}
                                  label="Banco"
                                />
                                <SearchableSelectBox
                                  createLabel="Crear Cuenta"
                                  onCreateNew={(e) =>
                                    CrearNuevaCuenta(
                                      e,
                                      item.FKCodigoProveedor,
                                      item.FKCodigoBanco
                                    )
                                  }
                                  loading={isCuentasProvLoading}
                                  options={
                                    cuentasProveedor
                                      ? cuentasProveedor.filter(
                                          (f) =>
                                            f.FKCodigoProveedor ===
                                              item?.FKCodigoProveedor &&
                                            f.FKCodigoBanco ===
                                              item?.FKCodigoBanco
                                        )
                                      : []
                                  }
                                  fullWidth={true}
                                  name="AcCuentasProveedores"
                                  value={{
                                    id: item?.FKCodigoCuentaProveedor || "",
                                    label: item?.NumeroCuenta || "",
                                  }}
                                  onChange={async (e, value) => {
                                    const newCotizaciones = listaCotizaciones;
                                    newCotizaciones[index] = {
                                      ...newCotizaciones[index],
                                      FKCodigoCuentaProveedor: value?.id,
                                      NumeroCuenta: value?.label,
                                    };
                                    await EditarCotizacionesSolicitud(
                                      form.Codigo,
                                      newCotizaciones
                                    );
                                  }}
                                  label="Número de Cuenta"
                                />
                                <Tooltip title="Eliminar" placement="top">
                                  <RemoveCircle
                                    style={{ fontSize: 20 }}
                                    color="error"
                                    className="mb-1 hover:cursor-pointer hover:scale-110"
                                    onClick={() =>
                                      EliminarArchivoFirebase(
                                        item.Ruta,
                                        item.Codigo,
                                        "Cotización"
                                      )
                                    }
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                  {isCotizacionesLoading && (
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} />
                      </Grid>
                      <Grid item xs={4}>
                        <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} />
                      </Grid>
                      <Grid item xs={4}>
                        <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} />
                      </Grid>
                    </Grid>
                  )}
                </div>
              </div>
            )}
            {modal.titulo === "Enviar Cotizaciones" && (
              <div className="mt-2">
                <div className="flex justify-center">
                  <span className="ConfirmarModal-text">
                    ¿Está seguro(a) que desea enviar las cotizaciones de la
                    solicitud?
                  </span>
                </div>
              </div>
            )}

            {modal.titulo === "Subir Comprobantes de Pago" && (
              <div className="flex flex-col gap-2 w-full h-full mt-4 p-2">
                <CustomMuiTextField
                  fullWidth={true}
                  label="Razón"
                  name="Razon"
                  placeholder="Razón"
                  value={form.Razon}
                  readOnly={true}
                  multiline
                />
                <div className="flex gap-1">
                  <CustomMuiTextField
                    fullWidth={true}
                    label="Proyecto"
                    name="Proyecto"
                    placeholder="Proyecto"
                    value={form.Proyecto}
                    readOnly={true}
                    multiline
                  />
                  <CustomMuiTextField
                    fullWidth={true}
                    label="Prioridad"
                    name="Prioridad"
                    placeholder="Prioridad"
                    value={form.Prioridad}
                    readOnly={true}
                  />
                </div>
                <div className="flex-col flex-nowrap gap-2 w-full">
                  <h4 className="font-bold mb-2">Cotizaciones</h4>
                  {listaCotizaciones.length > 0 && (
                    <Grid container spacing={2}>
                      {listaCotizaciones.map((item, index) => {
                        return (
                          <Grid key={index} item xs={12}>
                            <div className="flex flex-col gap-2">
                              <div>
                                <Tooltip
                                  title="Ver Cotización"
                                  placement="right"
                                  className="hover:cursor-pointer"
                                  onClick={() =>
                                    window.open(item.Url, "_blank")
                                  }
                                >
                                  <label>{item.Nombre}</label>
                                </Tooltip>
                              </div>
                              <div className="flex flex-nowrap gap-2 items-center">
                                <CustomMuiTextField
                                  fullWidth={true}
                                  label="Proveedor"
                                  name="Proveedor"
                                  placeholder="Proveedor"
                                  value={item.Proveedor}
                                  readOnly={true}
                                />
                                <CustomMuiTextField
                                  fullWidth={true}
                                  label="RTN"
                                  name="RTN"
                                  placeholder="RTN"
                                  value={item.Rtn}
                                  readOnly={true}
                                />
                                <CustomMuiTextField
                                  fullWidth={true}
                                  label="Banco"
                                  name="Banco"
                                  placeholder="Banco"
                                  value={item.Banco}
                                  readOnly={true}
                                />
                                <CustomMuiTextField
                                  fullWidth={true}
                                  label="Número de Cuenta"
                                  name="Número de Cuenta"
                                  placeholder="Número de Cuenta"
                                  value={item.NumeroCuenta}
                                  readOnly={true}
                                />
                              </div>
                            </div>
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                </div>
                {estadoBoton && (
                  <div>
                    <input
                      accept=".png,.jpg,.jpeg,.pdf"
                      style={{ display: "none" }}
                      id="IptSubirComprobante"
                      multiple={true}
                      type="file"
                      onChange={CargarComprobantesArchivosFirebase}
                    />
                    <label htmlFor="IptSubirComprobante">
                      <CustomMuiButton
                        name="BtnSubirComprobante"
                        id="BtnSubirComprobante"
                        variant="contained"
                        component="span"
                        style={{ fontSize: 11 }}
                        buttomName="Subir Comprobante"
                        icon={<FileUpload style={estiloIcono} />}
                      />
                    </label>
                  </div>
                )}
                <div className="flex-col flex-nowrap gap-2 w-full">
                  <h4 className="font-bold mb-2">Comprobantes de Pago</h4>
                  {listaComprobantes.length > 0 && (
                    <Grid container spacing={2}>
                      {listaComprobantes.map((item, index) => {
                        return (
                          <Grid key={index} item xs={4}>
                            <div className="flex gap-2">
                              <Tooltip
                                title="Ver Comprobante"
                                placement="bottom"
                              >
                                <p
                                  onClick={() =>
                                    window.open(item.Url, "_blank")
                                  }
                                  className="hover:cursor-pointer hover:scale-110"
                                >
                                  {item.Nombre}
                                </p>
                              </Tooltip>
                              <Tooltip title="Eliminar" placement="top">
                                <Delete
                                  color="error"
                                  style={{
                                    fontSize: 20,
                                    marginBottom: "1px",
                                    marginLeft: "2px",
                                  }}
                                  className="hover:cursor-pointer hover:scale-110"
                                  onClick={() =>
                                    EliminarArchivoFirebase(
                                      item.Ruta,
                                      item.Codigo,
                                      "Comprobante"
                                    )
                                  }
                                />
                              </Tooltip>
                            </div>
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                  {isComprobantesLoading && (
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} />
                      </Grid>
                      <Grid item xs={4}>
                        <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} />
                      </Grid>
                      <Grid item xs={4}>
                        <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} />
                      </Grid>
                    </Grid>
                  )}
                </div>
              </div>
            )}
            {modal.titulo === "Rechazar Cotización" && (
              <div className="mt-2">
                <div className="flex flex-col justify-center gap-2">
                  <div className="RechazarModal-text gap-2">
                    <div className="flex flex-col gap-2">
                      <div>
                        ¿Está seguro que desea rechazar la cotización de compra?
                      </div>
                    </div>
                  </div>
                  <div className="text-slate-400 text-sm">
                    Debe llenar las observaciones para rechazar una cotización
                  </div>
                  <CustomMuiTextField
                    fullWidth={true}
                    label="Observaciones"
                    name="Observaciones"
                    placeholder="Observaciones"
                    value={form.Observaciones}
                    onChange={(e) => {
                      setForm((prev) => {
                        return { ...prev, Observaciones: e.target.value };
                      });
                    }}
                    required
                    multiline
                    InputPropsOptions={{ maxLength: 250 }}
                  />
                </div>
              </div>
            )}
            {modal.titulo === "Enviar Comprobantes de Pago" && (
              <div className="mt-2">
                <div className="flex justify-center">
                  <span className="ConfirmarModal-text">
                    ¿Está seguro(a) que desea enviar los comprobantes de pago de
                    la solicitud?
                  </span>
                </div>
              </div>
            )}
            {modal.titulo === "¿Entregado en Bodega?" && (
              <div className="w-full">
                <div className="mt-2 flex flex-col gap-2 w-full">
                  <h4 className="font-bold ml-2">Artículos</h4>
                  <div
                    className="flex flex-col gap-2 w-full overflow-y-scroll"
                    ref={containerRef}
                  >
                    {arregloArticulos.map((item, index) => {
                      return (
                        <div
                          className="flex gap-1 items-center p-1"
                          key={index}
                        >
                          <CustomMuiTextField
                            label="Nombre"
                            name="Nombre"
                            placeholder="Nombre "
                            value={item.Nombre.toUpperCase()}
                            readOnly={true}
                            multiline
                            fullWidth={true}
                            InputPropsOptions={{ maxLength: 250 }}
                          />
                          <CustomMuiTextField
                            label="Cantidad"
                            name="Cantidad"
                            placeholder="Cantidad"
                            value={item.Cantidad}
                            readOnly={true}
                            sx={{ width: "40%" }}
                            InputPropsOptions={{ maxLength: 22 }}
                          />
                          <CustomMuiTextField
                            label="Métrica"
                            name="Metrica"
                            placeholder="Métrica "
                            value={item.Metrica}
                            readOnly={true}
                            fullWidth={true}
                            sx={{ minwidth: "45%" }}
                          />
                          <SearchableSelectBox
                            createLabel="Crear Bodega"
                            onCreateNew={CrearNuevaBodega}
                            loading={isBodegasLoading}
                            options={listaBodegas}
                            fullWidth={true}
                            name="AcBodegas"
                            value={{
                              id: item?.FKCodigoBodega || "",
                              label: item?.NombreBodega || "",
                            }}
                            onChange={(e, value) => {
                              setArregloArticulos((prev) => {
                                const newArreglo = [...prev];
                                newArreglo[index] = {
                                  ...newArreglo[index],
                                  FKCodigoBodega: value?.id,
                                  NombreBodega: value?.label,
                                };
                                return newArreglo;
                              });
                            }}
                            label="Bodegas"
                          />
                        </div>
                      );
                    })}
                    {isArticulosLoading && (
                      <div className="mx-1">
                        <Grid container spacing={1}>
                          <Grid item xs={9}>
                            <Skeleton
                              variant="rounded"
                              sx={{ fontSize: "2rem" }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Skeleton
                              variant="rounded"
                              sx={{ fontSize: "2rem" }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {modal.titulo === "¿Cerrar Solicitud?" && (
              <div className="mt-2">
                <div className="flex justify-center">
                  <span className="ConfirmarModal-text">
                    ¿Está seguro(a) que desea cerrar la solicitud?
                  </span>
                </div>
              </div>
            )}
            {modal.titulo === "Solicitud de Compra" && (
              <div className="flex flex-col gap-2 h-full mt-4 p-2">
                <CustomMuiTextField
                  fullWidth={true}
                  readOnly={true}
                  label="Razón"
                  name="Razon"
                  placeholder="Razón"
                  value={form.Razon}
                  multiline
                />
                <CustomMuiTextField
                  fullWidth={true}
                  readOnly={true}
                  label="Proyecto"
                  name="Proyecto"
                  placeholder="Proyecto"
                  value={form.Proyecto}
                  multiline
                />
                <div className="flex gap-1 w-full">
                  <CustomMuiTextField
                    fullWidth={true}
                    readOnly={true}
                    label="Prioridad"
                    name="Prioridad"
                    placeholder="Prioridad"
                    value={form.Prioridad}
                    multiline
                  />
                  <CustomMuiTextField
                    fullWidth={true}
                    readOnly={true}
                    label="Etapa"
                    name="Etapa"
                    placeholder="Etapa"
                    value={form.Estado}
                    multiline
                  />
                </div>
                <div className="mt-2 flex-col flex-nowrap gap-2 w-full">
                  <h4 className="font-bold">Artículos</h4>
                  <div
                    className="flex flex-col gap-2 w-full overflow-y-scroll max-h-40 mt-2"
                    ref={containerRef}
                  >
                    {arregloArticulos.map((item, index) => {
                      return (
                        <div
                          className="flex gap-1 items-center mt-1"
                          key={index}
                        >
                          <CustomMuiTextField
                            label="Nombre"
                            name="Nombre"
                            placeholder="Nombre "
                            value={item.Nombre.toUpperCase()}
                            readOnly={true}
                            multiline
                            fullWidth={true}
                          />
                          <CustomMuiTextField
                            label="Métricas"
                            name="Metricas"
                            placeholder="Metricas "
                            value={item.Metrica}
                            readOnly={true}
                            fullWidth={true}
                          />
                          <CustomMuiTextField
                            label="Cantidad"
                            name="Cantidad"
                            placeholder="Cantidad"
                            value={item.Cantidad}
                            readOnly={true}
                            sx={{ width: "30%" }}
                          />
                        </div>
                      );
                    })}
                    {isArticulosLoading && (
                      <div className="mx-1">
                        <Grid container spacing={1}>
                          <Grid item xs={9}>
                            <Skeleton
                              variant="rounded"
                              sx={{ fontSize: "2rem" }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Skeleton
                              variant="rounded"
                              sx={{ fontSize: "2rem" }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-col flex-nowrap gap-2 w-full">
                  <h4 className="font-bold mb-2">Cotizaciones</h4>
                  {listaCotizaciones.length > 0 && (
                    <Grid container spacing={2}>
                      {listaCotizaciones.map((item, index) => {
                        return (
                          <Grid key={index} item xs={12}>
                            <div className="flex flex-col gap-2">
                              <div>
                                <Tooltip
                                  title="Ver Cotización"
                                  placement="right"
                                  className="hover:cursor-pointer"
                                  onClick={() =>
                                    window.open(item.Url, "_blank")
                                  }
                                >
                                  <label>{item.Nombre}</label>
                                </Tooltip>
                              </div>
                              <div className="flex flex-nowrap gap-2 items-center">
                                <CustomMuiTextField
                                  fullWidth={true}
                                  label="Proveedor"
                                  name="Proveedor"
                                  placeholder="Proveedor"
                                  value={item.Proveedor}
                                  readOnly={true}
                                />
                                <CustomMuiTextField
                                  fullWidth={true}
                                  label="RTN"
                                  name="RTN"
                                  placeholder="RTN"
                                  value={item.Rtn}
                                  readOnly={true}
                                />
                                <CustomMuiTextField
                                  fullWidth={true}
                                  label="Banco"
                                  name="Banco"
                                  placeholder="Banco"
                                  value={item.Banco}
                                  readOnly={true}
                                />
                                <CustomMuiTextField
                                  fullWidth={true}
                                  label="Número de Cuenta"
                                  name="Número de Cuenta"
                                  placeholder="Número de Cuenta"
                                  value={item.NumeroCuenta}
                                  readOnly={true}
                                />
                              </div>
                            </div>
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                </div>
                <div className="flex-col flex-nowrap gap-2 w-full">
                  <h4 className="font-bold mb-2">Comprobantes de Pago</h4>
                  {listaComprobantes.length > 0 && (
                    <Grid container spacing={2}>
                      {listaComprobantes.map((item, index) => {
                        return (
                          <Grid key={index} item xs={4}>
                            <div className="flex gap-2">
                              <Tooltip
                                title="Ver Comprobante"
                                placement="bottom"
                              >
                                <p
                                  onClick={() =>
                                    window.open(item.Url, "_blank")
                                  }
                                  className="hover:cursor-pointer hover:scale-110"
                                >
                                  {item.Nombre}
                                </p>
                              </Tooltip>
                            </div>
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                  {isComprobantesLoading && (
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} />
                      </Grid>
                      <Grid item xs={4}>
                        <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} />
                      </Grid>
                      <Grid item xs={4}>
                        <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} />
                      </Grid>
                    </Grid>
                  )}
                </div>
              </div>
            )}
            {modal.titulo === "Revision de Solicitud" && (
              <div className="flex flex-col gap-2 h-full mt-4 p-2">
                <CustomMuiTextField
                  fullWidth={true}
                  readOnly={true}
                  label="Razón"
                  name="Razon"
                  placeholder="Razón"
                  value={form.Razon}
                  multiline
                />
                <CustomMuiTextField
                  fullWidth={true}
                  readOnly={true}
                  label="Proyecto"
                  name="Proyecto"
                  placeholder="Proyecto"
                  value={form.Proyecto}
                  multiline
                />
                <div className="flex gap-1 w-full">
                  <CustomMuiTextField
                    fullWidth={true}
                    readOnly={true}
                    label="Prioridad"
                    name="Prioridad"
                    placeholder="Prioridad"
                    value={form.Prioridad}
                    multiline
                  />
                  <CustomMuiTextField
                    fullWidth={true}
                    readOnly={true}
                    label="Etapa"
                    name="Etapa"
                    placeholder="Etapa"
                    value={form.Estado}
                    multiline
                  />
                </div>
                <div className="mt-2 flex-col flex-nowrap gap-2 w-full">
                  <h4 className="font-bold ml-2">Artículos</h4>
                  <div
                    className="flex flex-col gap-2 w-full overflow-y-scroll max-h-40 mt-2"
                    ref={containerRef}
                  >
                    {arregloArticulos.map((item, index) => {
                      return (
                        <div
                          className="flex gap-1 items-center p-1 mt-1"
                          key={index}
                        >
                          <CustomMuiTextField
                            label="Nombre"
                            name="Nombre"
                            placeholder="Nombre "
                            value={item.Nombre.toUpperCase()}
                            readOnly={true}
                            multiline
                            fullWidth={true}
                          />
                          <CustomMuiTextField
                            label="Métricas"
                            name="Metricas"
                            placeholder="Metricas "
                            value={item.Metrica}
                            readOnly={true}
                            fullWidth={true}
                          />
                          <CustomMuiTextField
                            label="Cantidad"
                            name="Cantidad"
                            placeholder="Cantidad"
                            value={item.Cantidad}
                            readOnly={true}
                            sx={{ width: "30%" }}
                          />
                        </div>
                      );
                    })}
                    {isArticulosLoading && (
                      <div className="mx-1">
                        <Grid container spacing={1}>
                          <Grid item xs={9}>
                            <Skeleton
                              variant="rounded"
                              sx={{ fontSize: "2rem" }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Skeleton
                              variant="rounded"
                              sx={{ fontSize: "2rem" }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {modal.titulo === "Aprobar Solicitud" && (
              <div className="mt-2">
                <div className="flex justify-center">
                  <span className="ConfirmarModal-text">
                    ¿Está seguro(a) que desea aprobar la solicitud de compra?
                  </span>
                </div>
              </div>
            )}
            {modal.titulo === "Rechazar Solicitud" && (
              <div className="mt-2">
                <div className="flex justify-center">
                  <span className="RechazarModal-text">
                    ¿Está seguro(a) que desea rechazar la solicitud de compra?
                  </span>
                </div>
              </div>
            )}
          </NuevoCustomModal>
          {isDeleteModal && (
            <DeleteModal
              titulo="Eliminar Solicitud"
              formulario={form}
              save={EliminarSolicitud}
              visible={isDeleteModal}
              onClose={() => setIsDeleteModal(() => false)}
            />
          )}
          {isEnviarModal && (
            <ConfirmarModal
              titulo="Enviar Solicitud"
              formulario={form}
              save={EnviarSolicitud}
              visible={isEnviarModal}
              cuerpo="¿Está seguro(a) que desea enviar la solicitud?"
              onClose={() => setIsEnviarModal(() => false)}
            />
          )}
          <div className="Detalle-tabla-container-map aic anim">
            <MaterialReactTable
              columns={columnas}
              data={filas}
              getRowId={(originalRow) =>
                originalRow[Object.keys(originalRow)[0]]
              }
              enableColumnFilterModes
              enableGrouping
              enablePinning
              enableDensityToggle
              enableStickyHeader
              localization={MRT_Localization_ES}
              state={{
                isLoading: isGridloading,
              }}
              initialState={estadoInicialGrid(columnas)}
              editingMode={"Modal"}
              enableColumnOrdering
              enableEditing={true}
              muiTablePaginationProps={muiTablePaginationProps}
              enableRowActions={permisos?.permisosUsuario?.some((item) =>
                permisosActivarAcciones.some((item2) =>
                  item.permiso.includes(item2)
                )
              )}
              renderRowActions={({ row }) => (
                <div className="aic mx-2">
                  {permisos?.permisosUsuario?.some(
                    (item) => item.permiso === EDITAR_SOLICITUD_COMPRAS
                  ) &&
                    row.original.FKCodigoEstado === COMPRA_BORRADOR && (
                      <Tooltip arrow placement="left" title="Editar">
                        <IconButton
                          onClick={() => {
                            !isGridloading &&
                              ModalEditarSolicitud(row.original);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}
                  {permisos?.permisosUsuario?.some(
                    (item) => item.permiso === ELIMINAR_SOLICITUD_COMPRAS
                  ) &&
                    row.original.FKCodigoEstado === COMPRA_BORRADOR && (
                      <Tooltip arrow placement="right" title="Eliminar">
                        <IconButton
                          color="error"
                          onClick={() => {
                            !isGridloading && ModalEliminar(row.original);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    )}
                  {permisos?.permisosUsuario?.some(
                    (item) =>
                      item.permiso === APROBAR_RECHAZAR_SOLICITUD_COMPRAS
                  ) &&
                    row.original.FKCodigoEstado === COMPRA_REVISION && (
                      <Tooltip
                        arrow
                        placement="right"
                        title="Revision de Solicitud"
                      >
                        <IconButton
                          color="info"
                          onClick={() => {
                            !isGridloading &&
                              ModalRevisarSolicitud(row.original);
                          }}
                        >
                          <Visibility color="primary" />
                        </IconButton>
                      </Tooltip>
                    )}
                  {permisos?.permisosUsuario?.some(
                    (item) => item.permiso === ENVIAR_SOLICITUD_COMPRAS
                  ) &&
                    row.original.FKCodigoEstado === COMPRA_BORRADOR && (
                      <Tooltip arrow placement="right" title="Enviar Solicitud">
                        <IconButton
                          color="error"
                          onClick={() => {
                            !isGridloading && ModalEnviar(row.original);
                          }}
                        >
                          <Send color="warning" />
                        </IconButton>
                      </Tooltip>
                    )}
                  {permisos?.permisosUsuario?.some(
                    (item) => item.permiso === SUBIR_COTIZACIONES_COMPRAS
                  ) &&
                    row.original.FKCodigoEstado === COMPRA_ENVIO_COTIZACION && (
                      <Tooltip
                        arrow
                        placement="right"
                        title="Subir Cotizaciones"
                      >
                        <IconButton
                          color="error"
                          onClick={() => {
                            !isGridloading &&
                              ModalSubirCotizaciones(row.original);
                          }}
                        >
                          <UploadFile color="info" />
                        </IconButton>
                      </Tooltip>
                    )}
                  {permisos?.permisosUsuario?.some(
                    (item) =>
                      item.permiso === SUBIR_COMPROBANTES_PAGO &&
                      RECHAZAR_COTIZACION_COMPRAS
                  ) &&
                    row.original.FKCodigoEstado ===
                      COMPRA_ENVIO_COMPROBANTE && (
                      <Tooltip
                        arrow
                        placement="right"
                        title="Subir Comprobantes"
                      >
                        <IconButton
                          color="error"
                          onClick={() => {
                            !isGridloading &&
                              ModalSubirComprobantesPago(row.original);
                          }}
                        >
                          <UploadFile color="info" />
                        </IconButton>
                      </Tooltip>
                    )}
                  {permisos?.permisosUsuario?.some(
                    (item) => item.permiso === ENTREGADA_BODEGA
                  ) &&
                    row.original.FKCodigoEstado === COMPRA_ESPERA_ENTREGA && (
                      <Tooltip
                        arrow
                        placement="right"
                        title="¿Entregado en Bodega?"
                      >
                        <IconButton
                          color="error"
                          onClick={() => {
                            !isGridloading &&
                              ModalEntregadaBodega(row.original);
                          }}
                        >
                          <Checklist color="warning" />
                        </IconButton>
                      </Tooltip>
                    )}
                  {permisos?.permisosUsuario?.some(
                    (item) => item.permiso === CERRAR_SOLICITUD
                  ) &&
                    row.original.FKCodigoEstado === COMPRA_ENTREGADA_BODEGA && (
                      <Tooltip arrow placement="right" title="Cerrar Solicitud">
                        <IconButton
                          color="error"
                          onClick={() => {
                            !isGridloading &&
                              ModalCierreSolicitud(row.original);
                          }}
                        >
                          <AssignmentTurnedIn color="success" />
                        </IconButton>
                      </Tooltip>
                    )}
                  {permisos?.permisosUsuario?.some(
                    (item) => item.permiso === VISUALIZAR_SOLICITUD
                  ) &&
                    row.original.FKCodigoEstado !== COMPRA_BORRADOR && (
                      <Tooltip arrow placement="right" title="Ver Solicitud">
                        <IconButton
                          color="success"
                          onClick={() => {
                            !isGridloading && ModalVerSolicitud(row.original);
                          }}
                        >
                          <RemoveRedEyeOutlined color="success" />
                        </IconButton>
                      </Tooltip>
                    )}
                </div>
              )}
              muiTableBodyRowProps={({ row }) => ({
                onClick: row.getToggleSelectedHandler(),
                sx: { cursor: "pointer" },
              })}
              muiTablePaperProps={muiTablePaperProps}
              muiTableProps={muiTableProps}
              muiTableHeadProps={muiTableHeadProps}
              muiTableHeadCellProps={muiTableHeadCellProps}
              muiTableBodyProps={muiTableBodyProps}
              displayColumnDefOptions={displayColumnDefOptions}
              muiTopToolbarProps={muiTopToolbarProps}
              renderTopToolbarCustomActions={() => {
                return (
                  <>
                    <Stack
                      className="gap-2"
                      style={{ float: "left", marginBottom: "1rem" }}
                      direction="row"
                      useFlexGap
                      flexWrap="wrap"
                    >
                      <Button
                        name="btnAgregarModal"
                        id="btnAgregarModal"
                        className="rounded-xl"
                        color="success"
                        size="small"
                        onClick={ModalCrearSolicitud}
                        variant="contained"
                        style={{ fontSize: 11 }}
                      >
                        <Add style={estiloIcono} />
                        Agregar
                      </Button>
                    </Stack>
                  </>
                );
              }}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default memo(Compras);
