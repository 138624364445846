import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Auth } from "aws-amplify";
import Spinner from "../components//Spinner";

export default function ProtectedRoutes() {
  const [isLogged, setIsLogged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function checkAuthentication() {
      try {
        await Auth.currentAuthenticatedUser();
        setIsLogged(true);
      } catch (error) {
        localStorage.clear();
        sessionStorage.clear();
        setIsLogged(false);
      } finally {
        setIsLoading(false);
      }
    }

    if (isLoading) {
      checkAuthentication();
    }
  }, [isLoading]);

  if (isLoading) {
    return <Spinner />;
  }

  return isLogged ? <Navigate to="/Proyectos" /> : <Outlet />;
}
