import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import { Paper, Popper } from "@mui/material";
const CustomPopper = styled((props) => <Popper {...props} />)(() => ({
  zIndex: 1400,
}));
const CustomPaper = styled(Paper)(() => ({
  borderRadius: "12px",
}));
const SearchableSelectBox = ({
  options,
  name = "",
  value,
  style = null,
  fullWidth = false,
  sx = null,
  label = "",
  onChange = null,
  onCreateNew = null,
  disabled = false,
  readOnly = false,
  loading = false,
  createLabel = "Crear",
  ...otherProps
}) => {
  const [inputValue, setInputValue] = useState("");
  const sxStyles = {
    borderRadius: "12px",
    border: "0.5px #e6e8ec",
    color: "#23262f",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    backgroundColor: "transparent",
    ...sx,
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const filterOptions = (options, params) => {
    const filtered = options.filter((option) =>
      option.label.toLowerCase().includes(params.inputValue.toLowerCase())
    );

    if (
      params.inputValue !== "" &&
      !filtered.some((option) => option.label === params.inputValue)
    ) {
      filtered.push({
        inputValue: params.inputValue,
        label: `${createLabel} "${params.inputValue}"`,
      });
    }

    return filtered;
  };

  const handleChange = (event, newValue) => {
    if (newValue?.inputValue) {
      // Trigger the onCreateNew prop function
      if (onCreateNew) {
        onCreateNew(newValue.inputValue);
      }
    } else {
      if (onChange) {
        onChange(event, newValue);
      }
    }
  };

  return (
    <Autocomplete
      loading={loading}
      fullWidth={fullWidth}
      sx={sxStyles}
      name={name}
      disabled={disabled}
      value={value}
      readOnly={readOnly}
      onChange={handleChange}
      options={options}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={(option) => option.label || ""}
      renderInput={(params) => (
        <TextField
          sx={{
            borderRadius: "12px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
            },
            "& .MuiInputLabel-outlined": {
              borderRadius: "12px",
            },
          }}
          {...params}
          label={label}
          variant="outlined"
        />
      )}
      PopperComponent={CustomPopper}
      PaperComponent={CustomPaper}
      onInputChange={handleInputChange}
      filterOptions={filterOptions}
      {...otherProps}
    />
  );
};

export default SearchableSelectBox;
