import "./style.css";
import Modal from "..";
import { memo } from "react";
import Icon from "../../Icon";

const NuevoCustomModal = ({
  visible = false,
  onClose,
  titulo = "",
  children,
  botoneria,
  maxWidth = "w-2/5",
}) => {
  return (
    <Modal visible={visible} onClose={onClose} maxWidth={maxWidth}>
      <div className="Modal-container aic anim">
        <div className="Modal-head aic">
          <h4>{titulo}</h4>
          <Icon
            name="Close"
            onClick={onClose}
            className={`Modal-close ${
              visible ? "star-rotate hover" : "wave hover"
            }`}
          />
        </div>
        <div className="Modal-body-divider" />
        <form className="p-4 formulario">
          <div>{children}</div>
        </form>
        <div className="Modal-body-divider mb-1" />
        <div className="botoneriaFormulario">{botoneria}</div>
      </div>
    </Modal>
  );
};
export default memo(NuevoCustomModal);
