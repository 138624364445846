import "./style.css";
import Image from "../Image";
import logoImg from "../..//assets/img/brand/branding-30.png";

const Spinner = ({ full, children }) => {
  return (
    <div
      className={` ${
        full ? "spinner-full" : ""
      } spinner--container aic unselectable`}
    >
      <Image src={logoImg} className="rotate spinner-img" />
      {children}
    </div>
  );
};
export default Spinner;
