import "./style.css";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Header from "../Header";
const Body = ({ children }) => {
  return (
    <main className="body--container aic anim">
      <div className="body-inner aic anim">
        <Header />
        <div className="body-content aic mt-16">{children}</div>
        <Footer />
      </div>
    </main>
  );
};

export default Body;
