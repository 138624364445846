//COMPRAS
export const CREAR_SOLICITUD_COMPRAS = "CREAR_SOLICITUD_COMPRAS";
export const EDITAR_SOLICITUD_COMPRAS = "EDITAR_SOLICITUD_COMPRAS";
export const ELIMINAR_SOLICITUD_COMPRAS = "ELIMINAR_SOLICITUD_COMPRAS";
export const ENVIAR_SOLICITUD_COMPRAS = "ENVIAR_SOLICITUD_COMPRAS";
export const VER_COMPRAS = "VER_COMPRAS";
export const APROBAR_RECHAZAR_SOLICITUD_COMPRAS =
  "APROBAR_RECHAZAR_SOLICITUD_COMPRAS";
export const SUBIR_COTIZACIONES_COMPRAS = "SUBIR_COTIZACIONES_COMPRAS";
export const SUBIR_COMPROBANTES_PAGO = "SUBIR_COMPROBANTES_PAGO";
export const ENTREGADA_BODEGA = "ENTREGADA_BODEGA";
export const CERRAR_SOLICITUD = "CERRAR_SOLICITUD";
export const VISUALIZAR_SOLICITUD = "VISUALIZAR_SOLICITUD";
export const RECHAZAR_COTIZACION_COMPRAS = "RECHAZAR_COTIZACION_COMPRAS";
export const VER_HISTORIAL_COMPRAS = "VER_HISTORIAL_COMPRAS"; 

//CONTROL DE PERSONAL
export const VER_CONTROL_PERSONAL = "VER_CONTROL_PERSONAL";

//HISTORIAL DE PAGOS
export const VER_HISTORIAL_PAGOS = "VER_HISTORIAL_PAGOS";

//REEMBOLSOS
export const VER_REEMBOLSOS = "VER_REEMBOLSOS";
