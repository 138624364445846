import { TextField } from "@mui/material";

const CustomMuiTextField = ({
  name = "",
  variant = "outlined",
  style = null,
  fullWidth = false,
  sx = null,
  label = "",
  value = "",
  onChange = null,
  disabled = false,
  readOnly = false,
  InputPropsStyles = null,
  InputPropsOptions = {},
  ...otherProps
}) => {
  const inPropsStyles = {
    borderRadius: "12px",
    border: "0.5px #e6e8ec",
    color: "#23262f",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    backgroundColor: "transparent",
  };
  return (
    <TextField
      disabled={disabled}
      name={name}
      id={name}
      label={label}
      value={value}
      variant={variant}
      style={style}
      fullWidth={fullWidth}
      sx={sx}
      onChange={onChange}
      InputProps={{
        style: inPropsStyles,
        readOnly: readOnly,
        inputProps: InputPropsOptions,
      }}
      {...otherProps}
    />
  );
};

export default CustomMuiTextField;
