import { createSlice } from "@reduxjs/toolkit";

export const sessionSlice = createSlice({
  name: "session",
  initialState: {
    value: {
      correo: "",
      nombre: "",
      codigoRol: [0],
      codigo: 0,
      permisos: "",
    },
  },
  reducers: {
    logIn: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
    logOff: (state) => {
      state.value = {
        correo: "",
        nombre: "",
        codigoRol: [0],
        codigo: 0,
        permisos: "",
      };
    },
  },
});

export const { logIn, logOff } = sessionSlice.actions;
export const selectSession = (state) => state.session.value;

export default sessionSlice.reducer;
