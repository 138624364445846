import {
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import Image from "../Image";
import logoImg from "../../assets/img/brand/Innovar_logo vertical contorno2.png";
import { useNavigate } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const drawerWidth = 240;

const MenuColapsable = ({
  window,
  mostrarMenu = false,
  cerrarMenu = () => {},
  navItems = [],
}) => {
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const navigate = useNavigate();
  const [menu, setMenu] = useState(navItems);

  useEffect(()=> {
    setMenu(navItems);
  },[navItems])

  function AbrirSubMenu(index) {
    setMenu((prev) => {
      const newMenu = [...prev];
      newMenu[index] = {
        ...newMenu[index],
        abierto: !newMenu[index].abierto,
      };
      return newMenu;
    });
  }

  return (
    <Drawer
      container={container}
      variant="temporary"
      open={mostrarMenu}
      onClose={cerrarMenu}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: "block", sm: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
        },
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h6" sx={{ my: 2 }}>
          <Image src={logoImg} className="navbar-nav-brand" />
        </Typography>
        <Divider />
        <List sx={{ width: "100%" }} component="nav">
          {menu.map((item, index) => (
            <div key={index}>
              <ListItemButton
                sx={{ textAlign: "center" }}
                onClick={() => {
                  if (item.subMenu) {
                    AbrirSubMenu(index);
                  }
                }}
              >
                {item.subMenu && <ListItemText primary={item.nombre} />}
                {!item.subMenu && (
                  <a
                    className="text-[#777e90] text-lg underline2 font-bold w-full"
                    href={item.link}
                  >
                    {item.nombre}
                  </a>
                )}
                {item.subMenu &&
                  (item.abierto ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
              <div className="flex flex-col">
                {item.subMenu &&
                  item.itemsSubmenu.map((item2, index2) => {
                    return (
                      <Collapse
                        key={index2}
                        in={item.abierto}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItemButton sx={{ pl: 4 }}>
                            <a
                              className="text-[#777e90] text-lg underline2 font-bold w-full"
                              href={item2.link}
                            >
                              {item2.nombre}
                            </a>
                          </ListItemButton>
                        </List>
                      </Collapse>
                    );
                  })}
              </div>
            </div>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default memo(MenuColapsable);
