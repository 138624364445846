import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useState } from "react";
import { Button, IconButton } from "@mui/material";
import { styled } from "@mui/system";

const CustomStyledMenu = styled(Menu)({
  "& .MuiPaper-root": {
    borderRadius: "12px",
  },
});

export default function CustomMenu({
  id = "positioned-menu",
  titulo = "",
  icono = <KeyboardArrowDown color="action" />,
  isIconButton = false,
  opciones = [],
  ariaLabel = "menu",
  size = "large",
  anchorOrigin = {
    vertical: "top",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "left",
  },
  ...otherProps
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {opciones.length > 0 && (
        <div>
          {!isIconButton ? (
            <Button
              id="positioned-button"
              aria-controls={open ? id : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              endIcon={icono}
            >
              <div className="text-black">{titulo}</div>
            </Button>
          ) : (
            <IconButton
              size={size}
              aria-label={ariaLabel}
              aria-controls={id}
              aria-haspopup="true"
              onClick={handleClick}
              color="inherit"
            >
              <div>{titulo}</div>
              {icono}
            </IconButton>
          )}
          <CustomStyledMenu
            id={id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            {...otherProps}
          >
            {opciones.map((item, index) => {
              return (
                <MenuItem key={index}>
                  {item.link ? (
                    <a
                      className="text-[#777e90] text-lg underline2 font-bold w-full"
                      href={item.link}
                    >
                      {item.nombre}
                    </a>
                  ) : (
                    <div
                      className="text-[#777e90] text-lg underline2 font-bold w-full"
                      onClick={item.funcion}
                    >
                      {item.nombre}
                    </div>
                  )}
                </MenuItem>
              );
            })}
          </CustomStyledMenu>
        </div>
      )}
    </div>
  );
}
