import "./style.css";
import Modal from "..";
import Icon from "../../Icon";
import Button from "../../Button";

const ConfirmarModal = ({
  formulario,
  save,
  visible,
  onClose,
  titulo = "",
  cuerpo = "",
}) => {
  return (
    <Modal visible={visible} onClose={onClose}>
      <div className="ConfirmarModal-container aic anim">
        <div className="ConfirmarModal-head aic">
          <h4 className="aic" style={{ gap: "8px" }}>
            <Icon name="Error" />
            {titulo}
          </h4>

          <Icon
            name="Close"
            onClick={onClose}
            className={`ConfirmarModal-close ${
              visible ? "star-rotate hover" : "wave hover"
            }`}
          />
        </div>
        <div className="ConfirmarModal-body-divider" />
        <div className="ConfirmarModal-body aic">
          <span className="ConfirmarModal-text">{cuerpo}</span>
          <div className="ConfirmarModal-btns aic">
            <Button ghost type="button" onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button type="button" onClick={() => save(formulario)}>
              Aceptar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmarModal;
