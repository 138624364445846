import React, { useState, useCallback, useRef, useEffect } from "react";
import moment from "moment";
import Spinner from "../../components/common/spinner";
import NuevoCustomModal from "../../components/Modal/NuevoCustomModal/index";

import {
  ObtenerHistorialCompras,
  ObtenerComprobantesPago,
  ObtenerCotizaciones,
  ObtenerArticulosSolicitud,
} from "../../services/compras";
import { RemoveRedEyeOutlined, Task } from "@mui/icons-material";
import { Grid, IconButton, Skeleton, Tooltip } from "@mui/material";
import {
  displayColumnDefOptions,
  estadoInicialGrid,
  handleDecrypt,
  muiTableBodyProps,
  muiTableHeadCellProps,
  muiTableHeadProps,
  muiTablePaginationProps,
  muiTablePaperProps,
  muiTableProps,
  muiTopToolbarProps,
} from "../../util/util";
import { ref, uploadBytes } from "firebase/storage";
import { storage } from "../../firebase";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable from "material-react-table";
import toast from "react-hot-toast";
import CustomMuiTextField from "../../components/Input";
import BotonCustom from "../../components/Button";
import { VER_HISTORIAL_COMPRAS } from "../../constants/permisos";
import { useSelector } from "react-redux";
import { selectSession } from "../../features/sessionSlice";
import { COMPRA_CERRADA } from "../../constants/constants";

const Historial = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isGridloading, setIsGridloading] = useState(true);
  const estadoInicialModal = {
    visible: false,
    titulo: "",
    funcion: () => {},
    botoneria: null,
    maxWidth: null,
  };
  const [modal, setModal] = useState(estadoInicialModal);
  const [filas, setFilas] = useState([]);
  const estadoInicialForm = {
    Codigo: 0,
    Razon: "",
    Observaciones: "",
    FKCodigoProyecto: 0,
    Proyecto: "",
    FKCodigoPrioridad: 0,
    Prioridad: "",
    NumeroCuenta: "",
    Rtn: "",
  };
  const [form, setForm] = useState(estadoInicialForm);
  const session = useSelector(selectSession);
  const permisos = JSON.parse(handleDecrypt(session.permisos));
  const [listaCotizaciones, setListaCotizaciones] = useState([]);
  const [listaComprobantes, setListaComprobantes] = useState([]);
  const [estadoBoton, setEstadoBoton] = useState(true);
  const [isCotizacionesLoading, setIsCotizacionesLoading] = useState(false);
  const [isComprobantesLoading, setIsComprobantesLoading] = useState(false);
  const estadoInicialArticulos = [
    { Id: 1, Codigo: 0, Nombre: "", Cantidad: 1 },
  ];
  const [arregloArticulos, setArregloArticulos] = useState(
    estadoInicialArticulos
  );
  const containerRef = useRef(null);
  const [isArticulosLoading, setIsArticulosLoading] = useState(false);

  const permisosActivarAcciones = [VER_HISTORIAL_COMPRAS];

  function ObtenerIcono(valor) {
    if (valor === "Cerrada") {
      return (
        <div className="flex gap-2 items-center">
          <Task style={{ fontSize: 20 }} color="error" className="mr-1 mb-1" />
          {valor}
        </div>
      );
    }
    if (valor === "Alta") {
      return <div className="text-red-500 font-bold">{valor}</div>;
    }
    if (valor === "Media") {
      return <div className="text-yellow-500 font-bold">{valor}</div>;
    }
    if (valor === "Baja") {
      return <div className="text-green-500 font-bold">{valor}</div>;
    }
  }

  const columnas = [
    {
      accessorKey: "Codigo",
      header: "Núm.",
      visible: true,
      filterFn: "equals",
      minSize: 100,
      Cell: ({ cell }) => <div className="text-center">{cell.getValue()}</div>,
    },
    {
      accessorKey: "Proyecto",
      header: "Proyecto",
      visible: true,
      Cell: ({ cell }) => (
        <div className="whitespace-pre-wrap">{cell.getValue()}</div>
      ),
    },
    {
      accessorKey: "Razon",
      header: "Razón",
      visible: true,
      muiTableHeadCellProps: {
        style: { whiteSpace: "normal", overfloWrap: "normal" },
      },
      Cell: ({ cell }) => (
        <div className="whitespace-pre-wrap">{cell.getValue()}</div>
      ),
    },
    {
      accessorKey: "Prioridad",
      header: "Prioridad",
      visible: true,
      minSize: 100,
      Cell: ({ cell }) => ObtenerIcono(cell.getValue()),
    },
    {
      accessorKey: "Observaciones",
      header: "Observaciones",
      visible: false,
      muiTableHeadCellProps: {
        style: { whiteSpace: "normal", overfloWrap: "normal" },
      },
      Cell: ({ cell }) => (
        <div className="whitespace-pre-wrap">{cell.getValue()}</div>
      ),
    },
    {
      accessorKey: "Estado",
      header: "Etapa",
      visible: true,
      Cell: ({ cell }) => ObtenerIcono(cell.getValue()),
    },
    {
      accessorKey: "FechaCreacion",
      header: "Creado El",
      visible: true,
      Cell: ({ cell }) => moment(cell.getValue()).format("DD / MM / YYYY"),
      filterFn: "equals",
      minSize: 120,
    },
    {
      accessorKey: "FechaModificacion",
      header: "Modificado El",
      visible: false,
      Cell: ({ cell }) => moment(cell.getValue()).format("DD / MM / YYYY"),
      filterFn: "equals",
      minSize: 120,
    },
  ];

  const ObtenerHistCompras = useCallback(async () => {
    try {
      setIsGridloading(true);
      const response = await ObtenerHistorialCompras();
      setFilas(response?.result ? JSON.parse(response.result) : []);
      setIsGridloading(false);
    } catch (err) {
      setIsGridloading(false);
      toast.dismiss();
      toast.error(err?.message);
    }
  }, []);

  const ObtenerListaCotizaciones = useCallback(async (Codigo) => {
    try {
      setIsCotizacionesLoading(true);
      const response = await ObtenerCotizaciones(Codigo);
      setListaCotizaciones(() =>
        response?.result ? JSON.parse(response.result) : []
      );
      setIsCotizacionesLoading(false);
    } catch (err) {
      setIsCotizacionesLoading(false);
      console.log("ObtenerListaCotizaciones", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }, []);

  const ObtenerListaComprobantes = useCallback(async (Codigo) => {
    try {
      setIsComprobantesLoading(true);
      const response = await ObtenerComprobantesPago(Codigo);
      setListaComprobantes(() =>
        response?.result ? JSON.parse(response.result) : []
      );
      setIsComprobantesLoading(false);
    } catch (err) {
      setIsComprobantesLoading(false);
      console.log("ObtenerListaComprobantes", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }, []);

  const ObtenerArtiSolicitud = useCallback(async (CodigoSol) => {
    try {
      setIsArticulosLoading(true);
      const response = await ObtenerArticulosSolicitud(CodigoSol);
      setArregloArticulos(
        response?.result
          ? response.result === "[]"
            ? estadoInicialArticulos
            : JSON.parse(response.result)
          : estadoInicialArticulos
      );
      setIsArticulosLoading(false);
    } catch (err) {
      setIsArticulosLoading(false);
      console.log("ObtenerArtiSolicitud", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }, []);

  useEffect(() => {
    ObtenerHistCompras();
  }, [ObtenerHistCompras]);

  const CargarArchivosFirebase = async (e) => {
    try {
      setIsLoading(true);
      setEstadoBoton(false);
      const files = e.target.files;
      const allowedTypes = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "application/pdf",
      ];
      const validFiles = Array.from(files).filter((file) =>
        allowedTypes.includes(file.type)
      );
      const inValidFiles = Array.from(files).filter(
        (file) => !allowedTypes.includes(file.type)
      );
      if (validFiles.length === 0) {
        toast.dismiss();
        toast.error(
          "Error, solo es permitido subir archivos de tipo .png, .jpg, .jpeg y .pdf"
        );
        return;
      }
      if (inValidFiles.length > 0) {
        toast.dismiss();
        toast.error(
          "Error, solo es permitido subir archivos de tipo .png, .jpg, .jpeg y .pdf. Los archivos de otro tipo serán ignorados"
        );
      }
      for (const item of files) {
        const archivoRef = ref(
          storage,
          `Compras/Cotizaciones/${form.Codigo}/${item.name}`
        );
        await uploadBytes(archivoRef, item);
      }

      await ObtenerListaCotizaciones(form.Codigo);
      setEstadoBoton(true);
      setIsLoading(false);
    } catch (err) {
      console.log("CargarArchivosFirebase", err);
      setIsLoading(false);
      toast.dismiss();
      toast.error(err?.message);
    }
  };

  function CerrarModal() {
    setModal(() => estadoInicialModal);
    setForm(() => estadoInicialForm);
    setListaCotizaciones(() => []);
    setListaComprobantes(() => []);
    setArregloArticulos(() => estadoInicialArticulos);
  }

  async function ModalVerSolicitud(datos) {
    setIsLoading(true);
    await ObtenerListaCotizaciones(datos.Codigo);
    await ObtenerListaComprobantes(datos.Codigo);
    setForm({
      Codigo: datos.Codigo,
      Proyecto: datos.Proyecto,
      Prioridad: datos.Prioridad,
      Razon: datos.Razon,
      Estado: datos.Estado,
    });
    await ObtenerArtiSolicitud(datos.Codigo);
    setModal({
      titulo: "Solicitud de Compra",
      visible: true,
      maxWidth: "w-3/5",
    });
    setIsLoading(false);
  }

  return (
    <main className="body--container aic anim">
      <Spinner isLoading={isLoading} />
      <div className="Detalle-tabla aic anim fromleft">
        <div className="Detalle-tabla-container aic anim">
          <div className="Detalle-tabla-container-title aic anim">
            <div className="Detalle-tabla-container-title-left aic anim">
              <div className="Detalle-tabla-container-title-left-block aic anim">
                <h2 className="Detalle-tabla-container-title-left-block-title">
                  Historial de Compras
                </h2>
                <span className="Detalle-tabla-container-title-left-block-body aic">
                  Solicite sus compras
                </span>
              </div>
            </div>
          </div>
          <NuevoCustomModal
            onClose={CerrarModal}
            visible={modal.visible}
            titulo={modal.titulo}
            maxWidth={modal.maxWidth}
            botoneria={
              <>
                {modal.titulo === "Solicitud de Compra" && (
                  <div className="Modal-btns aic">
                    <BotonCustom ghost type="button" onClick={CerrarModal}>
                      Cerrar
                    </BotonCustom>
                  </div>
                )}
              </>
            }
          >
            {modal.titulo === "Solicitud de Compra" && (
              <div className="flex flex-col gap-2 h-full mt-4 p-2">
                <CustomMuiTextField
                  fullWidth={true}
                  readOnly={true}
                  label="Razón"
                  name="Razon"
                  placeholder="Razón"
                  value={form.Razon}
                  multiline
                />
                <CustomMuiTextField
                  fullWidth={true}
                  readOnly={true}
                  label="Proyecto"
                  name="Proyecto"
                  placeholder="Proyecto"
                  value={form.Proyecto}
                  multiline
                />
                <div className="flex gap-1 w-full">
                  <CustomMuiTextField
                    fullWidth={true}
                    readOnly={true}
                    label="Prioridad"
                    name="Prioridad"
                    placeholder="Prioridad"
                    value={form.Prioridad}
                    multiline
                  />
                  <CustomMuiTextField
                    fullWidth={true}
                    readOnly={true}
                    label="Etapa"
                    name="Etapa"
                    placeholder="Etapa"
                    value={form.Estado}
                    multiline
                  />
                </div>
                <div className="mt-2 flex-col flex-nowrap gap-2 w-full">
                  <h4 className="font-bold ml-2">Artículos</h4>
                  <div
                    className="flex flex-col gap-2 w-full overflow-y-scroll max-h-40 mt-2"
                    ref={containerRef}
                  >
                    {arregloArticulos.map((item, index) => {
                      return (
                        <div
                          className="flex gap-1 items-center p-1 mt-1"
                          key={index}
                        >
                          <CustomMuiTextField
                            label="Nombre"
                            name="Nombre"
                            placeholder="Nombre "
                            value={item.Nombre.toUpperCase()}
                            readOnly={true}
                            multiline
                            fullWidth={true}
                          />
                          <CustomMuiTextField
                            label="Métricas"
                            name="Metricas"
                            placeholder="Metricas "
                            value={item.Metrica}
                            readOnly={true}
                            fullWidth={true}
                          />
                          <CustomMuiTextField
                            label="Cantidad"
                            name="Cantidad"
                            placeholder="Cantidad"
                            value={item.Cantidad}
                            readOnly={true}
                            sx={{ width: "30%" }}
                          />
                        </div>
                      );
                    })}
                    {isArticulosLoading && (
                      <div className="mx-1">
                        <Grid container spacing={1}>
                          <Grid item xs={9}>
                            <Skeleton
                              variant="rounded"
                              sx={{ fontSize: "2rem" }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Skeleton
                              variant="rounded"
                              sx={{ fontSize: "2rem" }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </div>
                </div>
                {estadoBoton && (
                  <div>
                    <h4 className="font-bold ml-2">Cotizaciones</h4>
                    <input
                      accept=".png,.jpg,.jpeg,.pdf"
                      style={{ display: "none" }}
                      id="IptSubirCotizacion"
                      multiple={true}
                      type="file"
                      onChange={CargarArchivosFirebase}
                    />
                    <label htmlFor="IptSubirCotizacion"></label>
                  </div>
                )}
                {listaCotizaciones.length > 0 && (
                  <Grid container spacing={2}>
                    {listaCotizaciones.map((item, index) => {
                      return (
                        <Grid key={index} item xs={12}>
                          <div className="flex flex-col gap-2">
                            <div>
                              <Tooltip
                                title="Ver Cotización"
                                placement="top"
                                className="hover:cursor-pointer"
                                onClick={() => window.open(item.Url, "_blank")}
                              >
                                {item.Nombre}
                              </Tooltip>
                            </div>
                            <div className="flex flex-nowrap gap-2 items-center">
                              <CustomMuiTextField
                                fullWidth={true}
                                label="Proveedor"
                                name="Proveedor"
                                placeholder="Proveedor"
                                value={item?.Proveedor || ""}
                                InputPropsOptions={{ maxLength: 14 }}
                              />
                              <CustomMuiTextField
                                fullWidth={true}
                                label="RTN"
                                name="RTN"
                                placeholder="RTN"
                                value={item?.Rtn || ""}
                                InputPropsOptions={{ maxLength: 14 }}
                              />
                              <CustomMuiTextField
                                fullWidth={true}
                                label="Banco"
                                name="Banco"
                                placeholder="Banco"
                                value={item?.Banco || ""}
                                InputPropsOptions={{ maxLength: 14 }}
                              />
                              <CustomMuiTextField
                                fullWidth={true}
                                label="Número de Cuenta"
                                name="Número de Cuenta"
                                placeholder="Número de Cuenta"
                                value={item?.NumeroCuenta || ""}
                                InputPropsOptions={{ maxLength: 14 }}
                              />
                            </div>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
                {isCotizacionesLoading && (
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} />
                    </Grid>
                    <Grid item xs={4}>
                      <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} />
                    </Grid>
                    <Grid item xs={4}>
                      <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} />
                    </Grid>
                  </Grid>
                )}
                {listaComprobantes.length > 0 && (
                  <Grid container spacing={2}>
                    {listaComprobantes.map((item, index) => {
                      return (
                        <Grid key={index} item xs={6}>
                          <h4 className="font-bold ml-2">
                            Comprobantes de Pago
                          </h4>
                          <div className="flex gap-2">
                            <Tooltip title="Ver Comprobante" placement="bottom">
                              <p
                                onClick={() => window.open(item.Url, "_blank")}
                                className="hover:cursor-pointer hover:scale-110"
                              >
                                {item.Nombre}
                              </p>
                            </Tooltip>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
                {isComprobantesLoading && (
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} />
                    </Grid>
                    <Grid item xs={4}>
                      <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} />
                    </Grid>
                    <Grid item xs={4}>
                      <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} />
                    </Grid>
                  </Grid>
                )}
              </div>
            )}
          </NuevoCustomModal>
          <div className="Detalle-tabla-container-map aic anim">
            <MaterialReactTable
              columns={columnas}
              data={filas}
              getRowId={(originalRow) =>
                originalRow[Object.keys(originalRow)[0]]
              }
              enableColumnFilterModes
              enableGrouping
              enablePinning
              enableDensityToggle
              enableStickyHeader
              localization={MRT_Localization_ES}
              state={{
                isLoading: isGridloading,
              }}
              initialState={estadoInicialGrid(columnas)}
              editingMode={"Modal"}
              enableColumnOrdering
              enableEditing={true}
              muiTablePaginationProps={muiTablePaginationProps}
              enableRowActions={permisos?.permisosUsuario?.some((item) =>
                permisosActivarAcciones.some((item2) =>
                  item.permiso.includes(item2)
                )
              )}
              renderRowActions={({ row }) => (
                <div className="aic mx-2">
                  {permisos?.permisosUsuario?.some(
                    (item) => item.permiso === VER_HISTORIAL_COMPRAS
                  ) &&
                    row.original.FKCodigoEstado === COMPRA_CERRADA && (
                      <Tooltip arrow placement="right" title="Ver Solicitud">
                        <IconButton
                          color="success"
                          onClick={() => {
                            !isGridloading && ModalVerSolicitud(row.original);
                          }}
                        >
                          <RemoveRedEyeOutlined color="success" />
                        </IconButton>
                      </Tooltip>
                    )}
                </div>
              )}
              muiTableBodyRowProps={({ row }) => ({
                onClick: row.getToggleSelectedHandler(),
                sx: { cursor: "pointer" },
              })}
              muiTablePaperProps={muiTablePaperProps}
              muiTableProps={muiTableProps}
              muiTableHeadProps={muiTableHeadProps}
              muiTableHeadCellProps={muiTableHeadCellProps}
              muiTableBodyProps={muiTableBodyProps}
              displayColumnDefOptions={displayColumnDefOptions}
              muiTopToolbarProps={muiTopToolbarProps}
              renderTopToolbarCustomActions={() => {}}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Historial;
