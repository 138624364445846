import { createSlice } from "@reduxjs/toolkit";

export const generalesSlice = createSlice({
  name: "generales",
  initialState: {
    value: {
      proyectos: [],
      pantallas: [],
    },
  },
  reducers: {
    inicializarValoresGenerales: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
    limpiarValoresGenerales: (state) => {
      state.value = {
        proyectos: [],
        pantallas: [],
      };
    },
  },
});

export const { inicializarValoresGenerales, limpiarValoresGenerales } =
  generalesSlice.actions;
export const selectGenerales = (state) => state?.generales?.value;
export default generalesSlice.reducer;
