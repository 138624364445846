import React, {useState} from 'react';
import {Tabs,Tab, Box, Typography} from '@mui/material';
import Compras from './index';
import Historial from './historial';
import Body from '../../components/Body';

function TabPanel(props) {
    const { children, value, index, ...other} = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}

function allProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls':`tabpanel-${index}`,
    };
}

const ComprasTabs = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue )=> {
        setValue(newValue);
    };

    return (
        <Body>
        <div>
            <Tabs value={value} onChange={handleChange} aria-label="compras tabs"  sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tab label="Compras" {...allProps(0)}  sx={{ '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',},}} />
                <Tab label="Historial" {...allProps(1)} sx={{ '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',},}}/>
            </Tabs>
            <TabPanel value={value} index={0}>
                <Compras />
            </TabPanel> 
            <TabPanel value={value} index={1}>
                <Historial />
            </TabPanel>
        </div>
        </Body>
    );
};

export default ComprasTabs;