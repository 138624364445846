import "./style.css";

const Button = ({
  secondary,
  warning,
  ghost,
  children,
  className,
  ...props
}) => {
  return (
    <button
      className={`button--container anim aic btn1 ${className ? className : ""} 
      ${secondary ? "button-secondary" : ""}
      ${ghost ? "button-ghost" : ""}
      ${warning ? "button-warning" : ""}
      `}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
