import {
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
} from "@mui/material";

const CustomMuiSelect = ({
  children,
  name = "",
  value = null,
  label = "",
  onChange = null,
  fullWidth = false,
  sx = {
    borderRadius: "12px",
    border: "0.5px #e6e8ec",
  },
  isLoading = false, // New prop for indicating loading state
  ...otherProps
}) => {
  return (
    <FormControl fullWidth={fullWidth} sx={{ minWidth: 165 }}>
      <InputLabel id={`slct-${name}`}>{label}</InputLabel>
      <Select
        {...otherProps}
        labelId={`slct-${name}`}
        id={name}
        value={value}
        label={label}
        onChange={onChange}
        sx={sx}
      >
        {isLoading ? (
          <div className="flex">
            <CircularProgress size={24} className="mx-4" />
            <p>Cargando</p>
          </div>
        ) : (
          children
        )}
      </Select>
    </FormControl>
  );
};
export default CustomMuiSelect;
