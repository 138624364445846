import {
  TYPERESULT_EXITOSO,
  TYPERESULT_ERROR_CONTROLADO,
  TYPERESULT_ERROR_NO_CONTROLADO,
} from "../constants/constants";
import { publicIpv4 } from "public-ip";
import base64 from "base-64";
import { lighten } from "@mui/material";

export const muiTablePaperProps = {
  elevation: 0,
  sx: {
    borderRadius: 2,
  },
};

export const muiTableProps = {
  sx: {
    tableLayout: "fixed",
  },
};

export const muiTableHeadProps = {
  sx: () => ({
    "& tr": {
      boxShadow: "none",
    },
    "& th": {
      backgroundColor: "#fff !important",
    },
    "& th:nth-of-type(1)": {
      borderRadius: "8px 0 0 8px",
    },
    "& th:nth-last-type(1)": {
      borderRadius: "0 8px 8px 0",
    },
  }),
};

export const muiTableHeadCellProps = {
  sx: {
    color: "#091F5A",
    fontWeight: "bold",
  },
};

export const muiTableBodyProps = {
  sx: (theme) => ({
    "& tr > td": {
      fontFamily: "Avenir Next, sans-serif",
    },
    "& tr:hover > td": {
      backgroundColor: lighten(theme.palette.primary.light, 0.9),
    },
    "& tr > td:nth-of-type(1)": {
      borderRadius: "8px 0 0 8px",
    },
    "& tr > td:nth-last-type(1)": {
      borderRadius: "0 8px 8px 0",
    },
    "& tr > td:nth-last-type(1) > div": {
      justifyContent: "center",
    },
  }),
};

export const displayColumnDefOptions = {
  "mrt-row-actions": {
    minSize: 100,
    grow: false,
    muiTableHeadCellProps: {
      size: "small",
    },
  },
};

export const muiTopToolbarProps = {
  sx: () => ({
    borderRadius: "8px 8px 8px 8px",
  }),
};

export const muiTablePaginationProps = {
  rowsPerPageOptions: [10, 20, 30, 50, 80, 100],
};

export function visibilidadColumnas(columnas) {
  let objDinamico = {};
  columnas.map((item) => {
    if (item?.visible === false) {
      return (objDinamico = { ...objDinamico, [item.accessorKey]: false });
    } else {
      return null;
    }
  });
  return objDinamico;
}

export function estadoInicialGrid(columnas) {
  return {
    columnVisibility: visibilidadColumnas(columnas),
    density: "compact",
    showColumnFilters: true,
  };
}

export const estilosModal = {
  marginTop: "1rem",
  justifyContent: "end",
};

export const estilosFooterModal = {
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  height: 70,
  padding: "0 20px",
  borderRadius: 12,
};

export default function ObjToParam(obj) {
  return new URLSearchParams(obj).toString();
}

export function handleEncrypt(value) {
  const encryptedMessage = base64.encode(value);
  return encryptedMessage;
}

export function handleDecrypt(value) {
  if (value) {
    const decryptedMessage = base64.decode(value);
    return decryptedMessage;
  } else {
    return null;
  }
}

export function formatPhoneNumber(phoneNumber) {
  // Remove all non-digit characters except the plus sign
  const cleaned = phoneNumber.replace(/[^\d+]/g, "");

  // Apply formatting based on the presence of a plus sign
  if (cleaned.includes("+")) {
    // Format as +XXX XXXX-XXXX
    return cleaned.replace(/(\+\d{3})(\d{4})(\d{4})/, "$1 $2-$3");
  } else {
    // Format as XXXX-XXXX
    return cleaned.replace(/(\d{4})(\d{4})/, "$1-$2");
  }
}

export const emptyArray = Array(49).fill(0);

export const estiloIcono = {
  fontSize: 15,
  marginBottom: "1px",
  marginRight: "3px",
};

export const estiloIconoBoton = {
  fontSize: 15,
  marginLeft: "3px",
  marginBottom: "2px",
};

export function ObtenerJwt() {
  try {
    const datUsua = localStorage.getItem("datUsua");
    if (datUsua) {
      const datEncript = DesEncriptarDatos(datUsua);
      let parseado;
      if (datEncript) parseado = JSON.parse(datEncript);
      return parseado.jwt;
    } else {
      return "";
    }
  } catch (err) {
    console.log(err);
    return "";
  }
}

export const ObtenerSeveridad = (sev) => {
  switch (sev) {
    case TYPERESULT_EXITOSO:
      return "success";
    case TYPERESULT_ERROR_CONTROLADO:
      return "warning";
    case TYPERESULT_ERROR_NO_CONTROLADO:
      return "error";
    default:
      return "error";
  }
};

async function getPublicIp() {
  let clientIP = await publicIpv4({
    fallbackUrls: ["https://ifconfig.co/ip"],
  });
  return clientIP;
}

function GetBrowserData() {
  var OSName = "Unknown OS";
  var BrowserName = "Unknown Browser";
  var BrowserVersion = "0";
  if (navigator?.userAgentData?.platform !== null)
    OSName = navigator?.userAgentData?.platform;

  if (navigator?.userAgentData?.brands[0] !== null) {
    BrowserName = navigator?.userAgentData?.brands[0]?.brand;
    BrowserVersion = navigator?.userAgentData?.brands[0]?.version;
  }
  return { OSName, BrowserName, BrowserVersion };
}

export async function EncriptarDatos(data) {
  try {
    if (!data) throw new Error("Datos indefinidos.");
    let localData;
    if (typeof data === "object") {
      localData = JSON.stringify(data);
    } else if (typeof data === "string") {
      localData = data;
    } else {
      throw new Error("Información no válida.");
    }
    let clientIP = await getPublicIp();

    const base64ClientIp = window.btoa(encodeURIComponent(clientIP));
    const base64BrowserData = window.btoa(
      encodeURIComponent(JSON.stringify(GetBrowserData()))
    );

    const base64SessionData = window.btoa(encodeURIComponent(localData));
    return Promise.resolve({
      datosUsua: base64SessionData,
      Ip: base64ClientIp,
      Browser: base64BrowserData,
    });
  } catch (error) {
    console.log("EncriptarDatos", error);
    throw new Error(error.message);
  }
}

export async function DesEncriptarDatos(Datos) {
  try {
    const base64SessionData = Datos;
    if (base64SessionData) {
      //let IPClienteActual = await getPublicIp();
      let InfoNavegadorActual = GetBrowserData();
      const InfoDesEncriptada = decodeURIComponent(
        window.atob(base64SessionData)
      );
      // const IPClienteDesEncriptado = decodeURIComponent(
      //   window.atob(sessionStorage.getItem("clip"))
      // );

      const InfoNavegadorDesEncriptado = decodeURIComponent(
        window.atob(localStorage.getItem("brs"))
      );
      let InfoNavegadorJson = JSON.parse(InfoNavegadorDesEncriptado);

      if (
        //IPClienteActual === IPClienteDesEncriptado &&
        InfoNavegadorActual.BrowserName === InfoNavegadorJson.BrowserName &&
        InfoNavegadorActual.BrowserVersion ===
          InfoNavegadorJson.BrowserVersion &&
        InfoNavegadorActual.OSName === InfoNavegadorJson.OSName
      ) {
        return InfoDesEncriptada;
      } else {
        localStorage.clear();
        throw new Error("Acceso Denegado");
      }
    } else {
      return "";
    }
  } catch (error) {
    console.log("DesEncriptarDatos", error);
    throw new Error(error.message);
  }
}
export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const getRandomBool = () => {
  return Math.random() >= 0.5;
};

export const localeString = (number) => {
  if (!!!number) return 0;
  return parseFloat(number).toLocaleString();
};
export const formatDateToLocalString = (date) => {
  return new Date(date).toLocaleDateString(undefined, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};
export const formatDateToLocalStringShort = (date) => {
  return new Date(date).toLocaleDateString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};
