import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCEXd5q6tehU8DptKg44VGopJJXQVTzO8k",
  authDomain: "grupoinnovarhn.firebaseapp.com",
  projectId: "grupoinnovarhn",
  storageBucket: "grupoinnovarhn.appspot.com",
  messagingSenderId: "725711343258",
  appId: "1:725711343258:web:d5ee8ab3a516ae63761923",
  measurementId: "G-M3VE04N080"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app);