import { configureStore } from "@reduxjs/toolkit";
import sessionReducer from "./features/sessionSlice";
import generalesReducer from "./features/generalesSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 2,
  storage,
};

const persistConfig2 = {
  key: "gral",
  version: 2,
  storage,
};

const persistedReducer = persistReducer(persistConfig, sessionReducer);

const persistedReducerGenerales = persistReducer(
  persistConfig2,
  generalesReducer
);

export const store = configureStore({
  reducer: {
    session: persistedReducer,
    generales: persistedReducerGenerales,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
