import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import Spinner from "../components/common/spinner";
import { useSelector } from "react-redux";
import { selectSession } from "../features/sessionSlice";
import { handleDecrypt } from "../util/util";
import {
  VER_COMPRAS,
  VER_CONTROL_PERSONAL,
  VER_HISTORIAL_PAGOS,
  VER_REEMBOLSOS,
} from "../constants/permisos";

const routePermissions = {
  "/ControlPersonal": [VER_CONTROL_PERSONAL],
  "/HistorialPagos": [VER_HISTORIAL_PAGOS],
  "/Reembolsos": [VER_REEMBOLSOS],
  "/Compras": [VER_COMPRAS],
};

export default function PrivateRoutes() {
  const [isLogged, setIsLogged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isTienePermisos, setIsTienePermisos] = useState();
  const location = useLocation();
  const session = useSelector(selectSession);
  const permisos = JSON.parse(handleDecrypt(session.permisos));
  const currentRoute = location.pathname;

  useEffect(() => {
    async function checkAuthentication() {
      try {
        await Auth.currentAuthenticatedUser();
        const requiredPermissions = routePermissions[currentRoute] || [];
        setIsTienePermisos(
          permisos?.permisosUsuario?.some((item) =>
            requiredPermissions.includes(item.permiso)
          )
        );
        setIsLogged(true);
      } catch (error) {
        localStorage.clear();
        sessionStorage.clear();
        setIsLogged(false);
      } finally {
        setIsLoading(false);
      }
    }

    if (isLoading) {
      checkAuthentication();
    }
  }, [isLoading]);

  if (isLoading) {
    return <Spinner isLoading={isLoading} />;
  } else {
    return isLogged ? (
      isTienePermisos ? (
        <Outlet />
      ) : (
        <Navigate to="/Proyectos" />
      )
    ) : (
      <Navigate to="/LogIn" />
    );
  }
}
