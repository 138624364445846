import { memo, useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { selectSession } from "../../features/sessionSlice";
import { handleDecrypt, handleEncrypt } from "../../util/util";
import MenuColapsable from "../Menu/MenuColapsable";
import CustomAppBar from "./CustomAppBar";
import { useDispatch } from "react-redux";
import { inicializarValoresGenerales } from "../../features/generalesSlice";
import { GetProyectos } from "../../services/proyectos";
import toast from "react-hot-toast";
import { selectGenerales } from "../../features/generalesSlice";
import {
  VER_COMPRAS,
  VER_CONTROL_PERSONAL,
  VER_HISTORIAL_PAGOS,
  VER_REEMBOLSOS,
} from "../../constants/permisos";
import Spinner from "../common/spinner";

const Header = () => {
  const session = useSelector(selectSession);
  const permisos = JSON.parse(handleDecrypt(session.permisos));
  const codRoles = session?.codigoRol;
  const [mostrarMenu, setMostrarMenu] = useState(false);
  const dispatch = useDispatch();
  const generales = useSelector(selectGenerales);
  const [listaProyectos, setListaProyectos] = useState(
    generales?.proyectos?.length > 0 ? generales.proyectos : []
  );
  const [listaHerramientas, setListaHerramientas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [listaMenu, setListaMenu] = useState([
    {
      nombre: "Proyectos",
      link: "/Proyectos",
      subMenu: true,
      itemsSubmenu: listaProyectos,
      abierto: false,
    },
    
  ]);

  const handleDrawerToggle = () => {
    setMostrarMenu((prevState) => !prevState);
  };

  const ObtenerProyectos = useCallback(async () => {
    try {
      if (listaProyectos.length > 0) {
        return;
      }
      setIsLoading(true);
      const response = await GetProyectos();
      let resp = [];
      resp = response?.result
        .filter((f) => f.codigo !== 6)
        .map((item) => {
          return {
            codigo: handleEncrypt(item.codigo),
            nombre: item.nombre.replace("Residencial", ""),
            orden: item.orden,
            link: "/DetalleProyecto?cp=" + handleEncrypt(item.codigo),
          };
        });
      resp.unshift({
        nombre: "Todas",
        orden: 0,
        link: "/Proyectos",
      });
      dispatch(
        inicializarValoresGenerales({
          proyectos: resp ? resp : [],
        })
      );
      setListaProyectos(resp ? resp : []);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("ObtenerProyectos", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }, []);

  function ObtenerLinkPantalla(permiso) {
    let resp;
    switch (permiso) {
      case VER_COMPRAS:
        resp = { nombre: "Compras", link: "/Compras" };
        break;
      case VER_CONTROL_PERSONAL:
        resp = { nombre: "Control de Personal", link: "/ControlPersonal" };
        break;
      case VER_HISTORIAL_PAGOS:
        resp = { nombre: "Historial de Pagos", link: "/HistorialPagos" };
        break;
      case VER_REEMBOLSOS:
        resp = { nombre: "Reembolsos", link: "/Reembolsos" };
        break;
      default:
        resp = null;
    }
    return resp;
  }

  const ObtenerListaPantallas = useCallback(async () => {
    try {
      if (permisos === null) {
        return;
      }
      let pantallas = permisos?.permisosUsuario;
      if (!pantallas) {
        return;
      }
      pantallas = pantallas
        ?.filter((item1) => item1.permiso.startsWith("VER_"))
        .map((item2) => {
          return ObtenerLinkPantalla(item2.permiso);
        });
      const nonNullPantallas = pantallas.filter((item) => item !== null);
      const uniquePantallas = nonNullPantallas.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.nombre === item.nombre)
      );
      setListaHerramientas(uniquePantallas);
    } catch (err) {
      console.log("ObtenerListaPantallas", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }, []);

  useEffect(() => {
    ObtenerProyectos();
    ObtenerListaPantallas();
  }, [ObtenerProyectos, ObtenerListaPantallas]);

  useEffect(() => {
    const updatedListaMenu = listaMenu.map((item) => {
      if (item.nombre === "Proyectos") {
        return {
          ...item,
          itemsSubmenu: listaProyectos,
        };
      }
      if (item.nombre === "Herramientas") {
        return {
          ...item,
          itemsSubmenu: listaHerramientas,
        };
      }
      return item;
    });
    setListaMenu(updatedListaMenu);
  }, [listaProyectos, listaHerramientas]);

  const navItems = listaMenu.slice();

  if(listaHerramientas.length > 0) {
    navItems.push({
      nombre: "Herramientas",
      subMenu: true,
      itemsSubmenu: listaHerramientas,
      abierto: false,
    })
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Spinner isLoading={isLoading} />
      <CustomAppBar
        codRoles={codRoles}
        cerrarMenu={handleDrawerToggle}
        gruposMenu={[
          {
            titulo: "Residenciales",
            opciones: listaProyectos,
          },
          {
            titulo: "Herramientas",
            opciones: listaHerramientas,
          },
        ]}
      />
      <nav>
        <MenuColapsable
          cerrarMenu={handleDrawerToggle}
          mostrarMenu={mostrarMenu}
          navItems={navItems }
        />
      </nav>
    </Box>
  );
};

export default memo(Header);
