import "./assets/css/fonts.css";
import "./assets/css/global.css";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Toaster } from "react-hot-toast";
import CacheBuster from "react-cache-buster";
import packageJson from "../package.json";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CacheBuster
    currentVersion={packageJson.version}
    isEnabled={true}
    reloadOnDowngrade={true}
    isVerboseMode={false}
  >
    <>
      <Toaster
        toastOptions={{
          duration: 5000,
          className: "btn1",
          style: {
            borderRadius: "10px",
            background: "#333",
            fontWeight: "bold",
            color: "#fff",
            fontFamily: "var(--font-family)",
          },
        }}
      />
      <App />
    </>
  </CacheBuster>
);
//https://drive.google.com/drive/folders/1nSwCVAZ-f9EIRWwfNh5tOEiLsLHtgBlj?direction=a
//https://elevenstudiohn.com/residencial-senderos-del-bosque/
//
