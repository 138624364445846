import "./style.css";
import Icon from "../Icon";
import Image from "../Image";
import logoImg from "../..//assets/img/brand/Innovar_logo principal negro.png";
import { Link } from "@mui/material";
const Footer = () => {
  return (
    <footer className="footer--container aic">
      <div className="footer-top aic">
        <Image src={logoImg} className="footer-logo " />
        <div className="footer-socials aic">
          <Link
            href="https://www.facebook.com/profile.php?id=100087803118575&mibextid=ZbWKwL"
            target="_blank"
            rel="noopener"
          >
            <Icon
              className="hover anim star-rotate text-slate-500 hover:scale-125 hover:text-blue-500"
              name="facebook"
            />
          </Link>
          <Link
            href="https://instagram.com/grupoinnovarhn?igshid=ZDdkNTZiNTM="
            target="_blank"
            rel="noopener"
          >
            <Icon
              className="hover anim star-rotate text-slate-500 hover:scale-125 hover:text-[#FF3F62]"
              name="instagram"
            />
          </Link>
          <Link
            href="https://api.whatsapp.com/send?phone=50496916706"
            target="_blank"
            rel="noopener"
          >
            <Icon
              className="hover anim star-rotate text-slate-500 hover:scale-125 hover:text-green-500"
              name="whatsapp"
              size="20"
            />
          </Link>
        </div>
      </div>
      <div className="footer-div" />
      <span className="footer-caption">
        Copyright © {new Date().getFullYear()} Grupo Innovar. Derechos
        Reservados.
      </span>
    </footer>
  );
};

export default Footer;
