import "./style.css";
import temp from "../..//assets/img//misc//temp.svg";
import { getRandomNumber } from "../..//util/util";
import { memo, useMemo } from "react";

const Img = ({ className, src, lazy, ...props }) => {
  let random_gen_img = useMemo(() => {
    if (src !== "random") return src;
    return `https://picsum.photos/seed/${getRandomNumber(1, 1000)}/200`;
  }, [src]);

  return (
    <figure className={`img-figure aic ${className}`} {...props}>
      <img
        src={src === "random" ? random_gen_img : !!src ? src : temp}
        draggable="false"
        loading={lazy ? "lazy" : "eager"}
        alt={src === "random" ? random_gen_img : !!src ? src : "temporary"}
        className="img-img unselectable"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = temp;
        }}
      />
    </figure>
  );
};

export default memo(Img);
