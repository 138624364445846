import "./style.css";
import Modal from "..";
import Icon from "../../Icon";
import Button from "../../Button";

const DeleteModal = ({ formulario, save, visible, onClose, titulo = "" }) => {
  return (
    <Modal visible={visible} onClose={onClose}>
      <div className="DeleteModal-container aic anim">
        <div className="DeleteModal-head aic">
          <h4 className="aic" style={{ gap: "8px" }}>
            <Icon name="Error" />
            {titulo}
          </h4>

          <Icon
            name="Close"
            onClick={onClose}
            className={`DeleteModal-close ${
              visible ? "star-rotate hover" : "wave hover"
            }`}
          />
        </div>
        <div className="DeleteModal-body-divider" />
        <div className="DeleteModal-body aic">
          <span className="DeleteModal-text">
            ¿Está seguro(a) que desea eliminar este registro?
          </span>
          <div className="DeleteModal-btns aic">
            <Button ghost type="button" onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button warning type="button" onClick={() => save(formulario)}>
              Eliminar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
