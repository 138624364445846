import { Button } from "@mui/material";
import "./style.css";

const CustomMuiButton = ({
  name = "",
  id = "",
  variant = "contained",
  component = null,
  style = null,
  fullWidth = false,
  sx = null,
  buttomName = "",
  icon = null,
  onClick = null,
  disabled = false,
  ...otherProps
}) => {
  return (
    <Button
      disabled={disabled}
      name={name}
      id={id}
      variant={variant}
      component={component}
      style={style}
      fullWidth={fullWidth}
      sx={sx}
      onClick={onClick}
      {...otherProps}
    >
      {buttomName}
      {icon}
    </Button>
  );
};

export default CustomMuiButton;
